<app-header></app-header>

<div class="container-fluid p-sm-5 bg-light">
    <div class="row">
        <div class="col-lg-12">
            <ul class="breadcrumb float-md-right">
                <li class="breadcrumb-item"><a class="color-blue" routerLink="/accueil"><i class="fa-solid fa-house"></i>
                        Accueil</a>
                </li>
                <li class="breadcrumb-item"><a class="color-blue" routerLink="/medecin/ConduitAtenir">
                        Conclusion + conduit à tenir</a>
                </li>
                <li class="breadcrumb-item active">Conduit à tenir</li>
            </ul>
        </div>
    </div>
    <app-consultation-header></app-consultation-header>
    <div class="card mt-5" style="border: none;box-shadow: 0 8px 8px rgba(34, 34, 34, 0.03), 0 4px 16px rgba(34, 34, 34, 0.1);">
        <div class="card-body">
            <div class="row justify-content-around">
                <div class="col-md-auto">
                    <a routerLink="" class="notSelected">Interrogatoire</a>
                </div>
                <div class="col-md-auto">
                    <a routerLink="" class="notSelected">Examen</a>
                </div>
                <div class="col-md-auto dropdown">
                    <a class="selected dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Conclusion + Conduit à tenir
                    </a>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" routerLink="/conclusion">Conclusion</a>
                        <a class="dropdown-item" routerLink="/Conduit-à-tenir">Conduit à tenir et prochaine consultation</a>
                    </div>
                </div>
                <div class="col-md-auto">
                    <a routerLink="" class="notSelected">Documents</a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-lg-12">
            <div class="card p-4" style="border: none;box-shadow: 0 8px 8px rgba(34, 34, 34, 0.03), 0 4px 16px rgba(34, 34, 34, 0.1);">
                <div class="card-body">
                    <form>
                        <div class="form-row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Conduite à tenir:</label>
                                    <ng-select [items]="listCat" class="form-control " [closeOnSelect]="true" multiple="true">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <div class="form-group">
                                    <label>Prochaine consultation:</label>
                                    <ng-select [items]="prochaineConsultation" class="form-control " [closeOnSelect]="true">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>Date et heure:</label>
                                    <input type="datetime-local" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Motif:</label>
                                    <ng-select [items]="listMotifs" class="form-control " [closeOnSelect]="true" multiple="true">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group text-right">
                            <button type="submit" class="btn btn-primary">Enregistrer</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</div>
<app-footer></app-footer>