export const villes = [
    {
      pays: 'Tunisie', ville: ['Ariana',
        'Beja',
        'Ben Arous',
        'Bizerte',
        'Gabes',
        'Gafsa',
        'Jendouba',
        'Kairouan',
        'Kasserine',
        'Kebili',
        'La Manouba',
        'Le Kef',
        'Mahdia',
        'Medenine',
        'Monastir',
        'Nabeul',
        'Sfax',
        'Sidi Bouzid',
        'Siliana',
        'Sousse',
        'Tataouine',
        'Tozeur',
        'Tunis',
        'Zaghouan']
}]
export const codepostale = [
  {
    ville: 'Ariana', code: ['2001',
      '2002',
      '2020',
      '2022',
      '2027',
      '2032',
      '2035',
      '2036',
      '2037',
      '2041',
      '2056',
      '2057',
      '2058',
      '2061',
      '2073',
      '2080',
      '2081',
      '2083',
      '2088',
      '2091',
      '2094',
      '2095']
  },
  {
    ville: 'Beja', code: ['9000',
      '9031',
      '9010',
      '9012',
      '9080',
      '9083',
      '9013',
      '9015',
      '9024',
      '9034',
      '9044',
      '9070',
      '9071',
      '9072',
      '9075',
      '9030',
      '9084',
      '9040',
      '9032',
      '9056',
      '9014',
      '9060',
      '9061',
      '9042',
      '9022',
      '9021',
      '9023',
      '9029',
      '9052']
},
  {
    ville: 'Ben Arous', code: ['2034',
      '2065',
      '1145',
      '2040',
      '2098',
      '1125',
      '2018',
      '2074',
      '2059',
      '2082',
      '1135',
      '2055',
      '2084',
      '1164',
      '2050',
      '2093',
      '2099',
      '2014',
      '2024',
      '2033',
      '2096',
      '2063',
      '2044',
      '2054',
      '2064',
      '2090',
      '2097',
      '2043',
      '2013']
},
  {
ville: 'Bizerte', code: ['7000',
      '7001',
      '7002',
      '7003',
      '7010',
      '7011',
      '7012',
      '7013',
      '7014',
      '7015',
      '7016',
      '7020',
      '7021',
      '7022',
      '7023',
      '7024',
      '7025',
      '7026',
      '7027',
      '7029',
      '7030',
      '7031',
      '7032',
      '7033',
      '7034',
      '7035',
      '7036',
      '7040',
      '7041',
      '7042',
      '7043',
      '7045',
      '7050',
      '7053',
      '7060',
      '7061',
      '7063',
      '7064',
      '7070',
      '7071',
      '7072',
      '7075',
      '7080',
      '7081',
      '7093',
      '7094',
      '7097',
      '7098']
  },
  {
ville: 'Gabes', code: ['6000',
      '6001',
      '6010',
      '6011',
      '6012',
      '6013',
      '6014',
      '6015',
      '6020',
      '6021',
      '6022',
      '6023',
      '6024',
      '6025',
      '6026',
      '6027',
      '6030',
      '6031',
      '6032',
      '6033',
      '6034',
      '6035',
      '6036',
      '6040',
      '6041',
      '6042',
      '6043',
      '6044',
      '6045',
      '6046',
      '6051',
      '6052',
      '6054',
      '6055',
      '6056',
      '6060',
      '6061',
      '6062',
      '6070',
      '6071',
      '6072',
      '6080',
      '6081',
      '6089',
      '6091',
      '6095',
      '6099']
},
  {
ville: 'Gafsa', code: ['2100',
      '2110',
      '2111',
      '2112',
      '2113',
      '2114',
      '2115',
      '2116',
      '2120',
      '2121',
      '2122',
      '2123',
      '2124',
      '2125',
      '2130',
      '2131',
      '2132',
      '2133',
      '2134',
      '2135',
      '2139',
      '2140',
      '2141',
      '2142',
      '2143',
      '2145',
      '2151',
      '2161',
      '2169',
      '2170',
      '2173',
      '2180',
      '2181',
      '2183',
      '2190',
      '2192',
      '2193',
      '2195',
      '2196']
  },
  {
    ville: 'Jendouba', code: ['8100',
      '8110',
      '8111',
      '8112',
      '8114',
      '8115',
      '8116',
      '8117',
      '8121',
      '8122',
      '8124',
      '8126',
      '8127',
      '8128',
      '8130',
      '8131',
      '8132',
      '8134',
      '8135',
      '8136',
      '8140',
      '8141',
      '8142',
      '8143',
      '8145',
      '8153',
      '8160',
      '8161',
      '8162',
      '8170',
      '8173',
      '8181',
      '8185',
      '8189',
      '8192',
      '8193',
      '8194',
      '8195',
      '8196']
  },
  {
ville: 'Kairouan', code: ['3100',
      '3110',
      '3111',
      '3112',
      '3113',
      '3114',
      '3115',
      '3116',
      '3120',
      '3121',
      '3124',
      '3125',
      '3126',
      '3129',
      '3130',
      '3131',
      '3132',
      '3133',
      '3134',
      '3135',
      '3140',
      '3142',
      '3143',
      '3145',
      '3150',
      '3151',
      '3152',
      '3153',
      '3154',
      '3160',
      '3161',
      '3170',
      '3171',
      '3173',
      '3180',
      '3181',
      '3182',
      '3183',
      '3191',
      '3192',
      '3193',
      '3194',
      '3195',
      '3196',
      '3198',
      '3199']
  },
  {
ville: 'Kasserine', code: ['1200',
      '1210',
      '1211',
      '1212',
      '1213',
      '1214',
      '1215',
      '1216',
      '1220',
      '1221',
      '1222',
      '1223',
      '1224',
      '1225',
      '1226',
      '1230',
      '1231',
      '1232',
      '1233',
      '1234',
      '1235',
      '1237',
      '1240',
      '1241',
      '1242',
      '1243',
      '1245',
      '1247',
      '1250',
      '1251',
      '1253',
      '1254',
      '1255',
      '1261',
      '1263',
      '1270',
      '1271',
      '1273',
      '1279',
      '1280',
      '1281',
      '1285',
      '1293',
      '1294']
  },
  {
    ville: 'Kebili', code: ['4200',
      '4210',
      '4211',
      '4212',
      '4213',
      '4214',
      '4215',
      '4216',
      '4222',
      '4223',
      '4224',
      '4230',
      '4231',
      '4232',
      '4234',
      '4235',
      '4236',
      '4237',
      '4242',
      '4243',
      '4253',
      '4260',
      '4261',
      '4263',
      '4264',
      '4273',
      '4274',
      '4280',
      '4283',
      '4293',
      '4294']
  },
  {
    ville: 'La Manouba', code: ['1110',
      '1113',
      '1114',
      '1116',
      '1124',
      '1128',
      '1130',
      '1133',
      '1134',
      '1142',
      '1143',
      '1144',
      '1153',
      '2010',
      '2011',
      '2012',
      '2021',
      '2031',
      '2071',
      '2075',
      '2086']
},
  {
    ville: 'Le Kef', code: ['7100',
      '7110',
      '7111',
      '7112',
      '7113',
      '7114',
      '7115',
      '7116',
      '7117',
      '7120',
      '7122',
      '7123',
      '7125',
      '7130',
      '7131',
      '7132',
      '7133',
      '7134',
      '7135',
      '7136',
      '7141',
      '7150',
      '7151',
      '7153',
      '7160',
      '7170',
      '7180',
      '7194']
},
  {
ville: 'Mahdia', code: ['5100',
      '5110',
      '5111',
      '5112',
      '5113',
      '5114',
      '5115',
      '5116',
      '5117',
      '5120',
      '5121',
      '5122',
      '5123',
      '5124',
      '5126',
      '5127',
      '5129',
      '5130',
      '5131',
      '5132',
      '5133',
      '5134',
      '5135',
      '5136',
      '5140',
      '5141',
      '5144',
      '5145',
      '5146',
      '5150',
      '5151',
      '5153',
      '5154',
      '5155',
      '5160',
      '5170',
      '5180',
      '5189',
      '5190',
      '5192',
      '5193',
      '5199']
  },
  {
ville: 'Medenine', code: ['4100',
      '4110',
      '4111',
      '4112',
      '4113',
      '4114',
      '4115',
      '4116',
      '4117',
      '4120',
      '4121',
      '4122',
      '4123',
      '4124',
      '4125',
      '4126',
      '4127',
      '4130',
      '4131',
      '4132',
      '4133',
      '4134',
      '4135',
      '4136',
      '4137',
      '4141',
      '4142',
      '4143',
      '4144',
      '4145',
      '4146',
      '4150',
      '4151',
      '4153',
      '4154',
      '4155',
      '4156',
      '4159',
      '4160',
      '4163',
      '4164',
      '4165',
      '4166',
      '4170',
      '4172',
      '4173',
      '4174',
      '4175',
      '4176',
      '4179',
      '4180',
      '4181',
      '4182',
      '4183',
      '4185',
      '4186',
      '4190',
      '4191',
      '4192',
      '4193',
      '4194',
      '4195',
      '4199']
},
  {
ville: 'Monastir', code: ['5000',
      '5010',
      '5011',
      '5012',
      '5013',
      '5014',
      '5015',
      '5016',
      '5017',
      '5020',
      '5021',
      '5022',
      '5023',
      '5024',
      '5025',
      '5026',
      '5027',
      '5028',
      '5031',
      '5032',
      '5033',
      '5034',
      '5035',
      '5036',
      '5040',
      '5041',
      '5042',
      '5043',
      '5044',
      '5045',
      '5046',
      '5051',
      '5052',
      '5053',
      '5054',
      '5060',
      '5061',
      '5062',
      '5063',
      '5065',
      '5066',
      '5070',
      '5071',
      '5076',
      '5079',
      '5080',
      '5089',
      '5090',
      '5091',
      '5092',
      '5099']
},
  {
ville: 'Nabeul', code: ['8000',
      '8013',
      '8070',
      '8076',
      '8024',
      '8033',
      '8063',
      '8073',
      '8051',
      '8020',
      '8041',
      '8031',
      '8010',
      '8091',
      '8080',
      '8062',
      '8060',
      '8023',
      '8066',
      '8021',
      '8099',
      '8014',
      '8050',
      '8056',
      '8042',
      '8032',
      '8045',
      '8046',
      '8074',
      '8026',
      '8016',
      '8036',
      '8035',
      '8055',
      '8065',
      '8069',
      '8090',
      '8094',
      '8030',
      '8012',
      '8093',
      '8052',
      '8082',
      '8084',
      '8092',
      '8089',
      '8044',
      '8071',
      '8061',
      '8022',
      '8040',
      '8011',
      '8075',
      '8025',
      '8096']
  },
  {
    ville: 'Sfax', code: ['3000',
      '3010',
      '3011',
      '3012',
      '3013',
      '3014',
      '3015',
      '3016',
      '3020',
      '3021',
      '3022',
      '3023',
      '3024',
      '3025',
      '3026',
      '3027',
      '3030',
      '3031',
      '3032',
      '3033',
      '3034',
      '3035',
      '3036',
      '3037',
      '3039',
      '3040',
      '3041',
      '3042',
      '3043',
      '3044',
      '3045',
      '3046',
      '3047',
      '3048',
      '3049',
      '3050',
      '3051',
      '3052',
      '3053',
      '3054',
      '3055',
      '3056',
      '3057',
      '3059',
      '3060',
      '3061',
      '3062',
      '3063',
      '3064',
      '3065',
      '3066',
      '3067',
      '3069',
      '3070',
      '3071',
      '3072',
      '3073',
      '3074',
      '3075',
      '3076',
      '3077',
      '3078',
      '3079',
      '3080',
      '3081',
      '3082',
      '3083',
      '3084',
      '3085',
      '3086',
      '3087',
      '3089',
      '3091',
      '3092',
      '3093',
      '3094',
      '3095',
      '3097',
      '3099']
  },
  {
    ville: 'Sidi Bouzid', code: ['9100',
      '9110',
      '9111',
      '9112',
      '9113',
      '9114',
      '9115',
      '9116',
      '9120',
      '9121',
      '9122',
      '9123',
      '9124',
      '9125',
      '9126',
      '9127',
      '9131',
      '9132',
      '9133',
      '9139',
      '9140',
      '9141',
      '9142',
      '9143',
      '9144',
      '9149',
      '9150',
      '9151',
      '9154',
      '9158',
      '9159',
      '9169',
      '9170',
      '9171',
      '9172',
      '9173',
      '9174',
      '9180',
      '9183']
  },
  {
ville: 'Siliana', code: ['6196',
      '6143',
      '6123',
      '6100',
      '6124',
      '6113',
      '6134',
      '6112',
      '6130',
      '6121',
      '6126',
      '6110',
      '6111',
      '9172',
      '6114',
      '6131',
      '6141',
      '6133',
      '6125',
      '6120',
      '6122',
      '6132',
      '6180',
      '6170',
      '6173',
      '6115',
      '6140',
      '6142',
      '6151',
      '6150',
      '6152',
      '6116',
      '6135']
  },
  {
    ville: 'Sousse', code: ['4000',
      '4004',
      '4010',
      '4011',
      '4012',
      '4013',
      '4014',
      '4015',
      '4016',
      '4017',
      '4020',
      '4021',
      '4022',
      '4023',
      '4024',
      '4025',
      '4026',
      '4030',
      '4031',
      '4032',
      '4033',
      '4034',
      '4035',
      '4040',
      '4041',
      '4042',
      '4043',
      '4045',
      '4051',
      '4054',
      '4059',
      '4060',
      '4061',
      '4062',
      '4063',
      '4070',
      '4071',
      '4081',
      '4082',
      '4089',
      '4092',
      '4095',
      '4099']
},
  {
ville: 'Tataouine', code: ['3200',
      '3211',
      '3212',
      '3213',
      '3214',
      '3215',
      '3217',
      '3220',
      '3221',
      '3222',
      '3223',
      '3224',
      '3225',
      '3232',
      '3233',
      '3235',
      '3240',
      '3241',
      '3242',
      '3243',
      '3244',
      '3245',
      '3251',
      '3252',
      '3253',
      '3261',
      '3262',
      '3263',
      '3264',
      '3271',
      '3272',
      '3274',
      '3282',
      '3284',
      '3286',
      '3293']
  },
  {
ville: 'Tozeur', code: ['2200',
      '2210',
      '2211',
      '2212',
      '2213',
      '2214',
      '2223',
      '2224',
      '2233',
      '2239',
      '2240',
      '2241',
      '2243',
      '2245',
      '2253',
      '2260',
      '2261',
      '2262',
      '2263']
  },
  {
ville: 'Tunis', code: ['1000',
      '1001',
      '1002',
      '1003',
      '1004',
      '1005',
      '1006',
      '1007',
      '1008',
      '1009',
      '1013',
      '1019',
      '1027',
      '1029',
      '1046',
      '1053',
      '1054',
      '1057',
      '1059',
      '1064',
      '1067',
      '1068',
      '1069',
      '1073',
      '1075',
      '1082',
      '1089',
      '1091',
      '1095',
      '2000',
      '2009',
      '2015',
      '2016',
      '2017',
      '2023',
      '2025',
      '2026',
      '2035',
      '2042',
      '2045',
      '2046',
      '2051',
      '2052',
      '2060',
      '2062',
      '2072',
      '2076',
      '2078',
      '2085',
      '2087',
      '2089',
      '2092']
  },
  {
    ville: 'Zaghouan', code: ['1154',
      '1160',
      '1163',
      '1140',
      '1146',
      '1162',
      '1194',
      '1123',
      '1131',
      '1141',
      '1193',
      '1111',
      '1100',
      '1132',
      '1121',
      '1155',
      '1112',
      '1122',
      '1152',
      '1115']
  }]
