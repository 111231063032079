export const specialites = [
  "Acupuncture",
  "Allergologue",
  "Anatomo-Cyto-Pathologiste",
  "Anesthésiste-Réanimateur",
  "Angiologue",
  "Audioprothésiste",
  "Audioprothésiste",
  "Biochimiste",
  "Biochimiste Clinique",
  "Biologiste Medicale",
  "Biophysique",
  "Cancérologue",
  "Cardiologue",
  "Cardiologie",
  "Chirurgien",
  "Chirurgien Cancérologue",
  "Chirurgien Cardio-Vasculaire",
  "Chirurgien Cardio-Vasculaire Thoracique",
  "Chirurgien Esthétique",
  "Chirurgien Généraliste",
  "Chirurgien Maxillo Facial Stomatologue",
  "Chirurgien Orthopédiste",
  "Chirurgien Orthopédiste Traumatologue",
  "Chirurgien Pédiatrique",
  "Chirurgien Plasticien",
  "Chirurgien Thoracique",
  "Chirurgien Urologue",
  "Dentiste",
  "Dermatologue",
  "Diabétologue",
  "Diététicien",
  "Embryologiste",
  "Endocrinologue",
  "Endocrinologue Diabétologue",
  "Gastro-entérologue",
  "Gériatre",
  "Généraliste",
  "Génétique",
  "Gynécologue",
  "Gynécologue Obstétricien",
  "Hématologue",
  "Hématologue Clinique",
  "Hématopathologiste",
  "Hépatologue",
  "Hypnothérapeute",
  "Imagerie Médicale",
  "Immunologiste",
  "Immunopathologiste",
  "Interniste",
  "Interniste Maladies Infectieuses",
  "Interniste Réanimation Médicale",
  "Kinésithérapeute",
  "Maladies Infectieuses",
  "Médecin Biologiste",
  "Médecin du Travail",
  "Médecin Esthétique",
  "Médecin Légiste",
  "Médecin Nucléaire",
  "Médecin Physique",
  "Médecin Physique Réadaptateur",
  "Médecine d’Urgence",
  "Médecine Physique",
  "réduction et réadaptation fonctionnelle",
  "Médecine Préventive",
  "Microbiologiste",
  "Néonatologiste",
  "Néphrologue",
  "Neurochirurgien",
  "Neurologue",
  "Nutritionniste",
  "Oncologue-Radiothérapeute",
  "Ophtalmologiste",
  "Orl",
  "Orthodontiste",
  "Orthopédiste",
  "Orthopédiste Traumatologue",
  "Orthophoniste",
  "Orthoptiste",
  "Ostéopathe",
  "Parasitologiste",
  "Pédiatre",
  "Pédodontiste",
  "Pédopsychiatre",
  "Pharmacien Biologiste",
  "Phlébologue",
  "Physiologiste",
  "Physiothérapeute",
  "Pneumologue",
  "Podologue",
  "Proctologue",
  "Proctologue",
  "Prothésiste dentaire",
  "Psychanalyste",
  "Psychiatre",
  "Psychologue",
  "Psychomotricien",
  "Psychothérapeute",
  "Radiologue",
  "Radiothérapeute",
  "Réanimateur Médical",
  "Rhumatologue",
  "Sexologue",
  "Stomatologue",
  "Urologue",
]
