export class ValidationService {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    let config = {
      'required': 'Champs invalide',
      'invalidCreditCard': 'Is invalid credit card number',
      'invalidEmailAddress': 'Email invalide. Réessayez !',
      'invalidPassword': 'Invalid password. Password must be at least 6 characters long, and contain a number.',
      'minlength': `${validatorValue.requiredLength} caractères minimum`,
      'maxlength': `${validatorValue.requiredLength} caractères maximum`,
      'invalidNumber': 'Champs invalide',
      'invalidate': 'Champs invalide'
    };

    return config[validatorName];
  }

  static creditCardValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
      return null;
    } else {
      return { 'invalidCreditCard': true };
    }
  }

  static numberValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (isNaN(control.value)) {
      return { 'invalidNumber': true };
    } else {
      return null;
    }
  }
  static dateValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    var today = new Date().toISOString().split('T')[0]
    if (control.value > today) {
      return { 'invalidate': true };
    } else {
      return null;
    }
  }
  static emailValidator(control) {
    // RFC 2822 compliant regex
    if (control.value) {
      if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
        return null;
      } else {
        return { 'invalidEmailAddress': true };
      }
    }
  }

  static passwordValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
      return null;
    } else {
      return { 'invalidPassword': true };
    }
  }
  static phonenumber(control) {
    if (control.value) {
      if (control.value[0] === '+' && control.value.length > 10) {
        return null;
      }
      else {
        return { 'invalidNumber': true };
      }
    }

  }
}
