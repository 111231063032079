
<div class="modal-header">
    <h4 class="modal-title">Historique des consultations</h4>
    <a class="exit" aria-label="Close" (click)="onReset()"><i class="fa-regular fa-circle-xmark"></i></a>
</div>
<div class="modal-body">
    <div>

            <table class="table">
                <thead>
                    <tr class="ligne-paire">
                        <th class="text-center" style="color:#589590;">Date</th>
                        <th class="text-center" style="color:#589590;">Motif de consultation</th>
                        <th class="text-center" style="color:#589590;">Durée</th>
                        <!-- Ajoutez d'autres colonnes au besoin -->
                    </tr>
                </thead>
                <tbody>
                    <tr class="ligne-impaire">
                        <td class="text-center">2024-05-01</td>
                        <td class="text-center">Motif</td>
                        <td class="text-center">00:15:00</td>
                        <!-- Ajoutez d'autres cellules au besoin -->
                    </tr>
                    <tr class="ligne-paire">
                        <td class="text-center">2024-05-01</td>
                        <td class="text-center">Motif</td>
                        <td class="text-center">00:15:00</td>
                        <!-- Ajoutez d'autres cellules au besoin -->
                    </tr>
                    <tr class="ligne-impaire">
                        <td class="text-center">2024-05-01</td>
                        <td class="text-center">Motif</td>
                        <td class="text-center">00:15:00</td>
                        <!-- Ajoutez d'autres cellules au besoin -->
                    </tr>
                    <tr class="ligne-paire">
                        <td class="text-center">2024-05-01</td>
                        <td class="text-center">Motif</td>
                        <td class="text-center">00:15:00</td>
                        <!-- Ajoutez d'autres cellules au besoin -->
                    </tr>
                    <tr class="ligne-impaire">
                        <td class="text-center">2024-05-01</td>
                        <td class="text-center">Motif</td>
                        <td class="text-center">00:15:00</td>
                        <!-- Ajoutez d'autres cellules au besoin -->
                    </tr>
                </tbody>
            </table>
        </div>

</div>