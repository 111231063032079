import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { API_CONFIG, _getURL } from 'config/API_CONFIG';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RdvsService {

  private apiUrl = _getURL(API_CONFIG.rdvs)

  constructor(private http: HttpClient) { }

  addRdvByMedecin(rdvData: any): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(this.apiUrl + '/addRdv/medecin', rdvData, { headers: headers })
  }

  getRdvsByMedecinId(medecinId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(`${this.apiUrl}/medecin/${medecinId}`, { headers: headers })
  }

  getRdvById(rdvId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(`${this.apiUrl}/${rdvId}`, { headers: headers })
  }

  getAllRdvsByDate(dt, id): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + '/today/' + dt + '/' + id, { headers: headers })
  }

  updateRdv(rdvId: string, rdvData: any): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(`${this.apiUrl}/update/${rdvId}`, rdvData, { headers: headers })
  }

  saveRDVeVENT(newRdv): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.rdvs) + '/addRdv/notify/patient', newRdv, { headers: headers })
  }

  annulerRdv(rdvId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(`${this.apiUrl}/cancel/${rdvId}`, {}, { headers: headers })
  }

  getRdvs(): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl, { headers });
  }

  getRdvsToday(date: string, medecinId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(`${this.apiUrl}/today/${date}/${medecinId}`, {headers: headers})
  }

  updateRdvStatus(rdvId: string, rdvNewStatus: any): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(`${this.apiUrl}/status/${rdvId}`, rdvNewStatus, {headers: headers})
  }

  confirmerRdv(rdvId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(`${this.apiUrl}/confirm/${rdvId}`, {}, { headers: headers })
  }
}
