<app-header></app-header>

    <div class="container-fluid p-sm-5 bg-light">
        <div class="row justify-content-center" *ngIf="medecins && medecins.length > 0">
            <div class="col-8 mb-4" *ngFor="let medecin of medecins">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Dr {{ medecin.nom }} {{ medecin.prenom }}</h5>
                        <p class="card-text">
                            <span class="label"><i class="fas fa-stethoscope"></i> Spécialité: </span> 
                            <span class="badge bg-color badge-responsive">{{ medecin.specialite }}</span>
                        </p>
                        <p class="card-text">
                            <span class="label"><i class="fa-solid fa-location-dot"></i> Adresse: </span> 
                            <span>{{ medecin.adresseCabinet.adresse }}   <b style="color: #21726B;"></b></span> 
                        </p>
                        <p class="card-text">
                            <span class="label"><i class="fa-solid fa-map-location"></i> Ville: </span> 
                            <span>{{medecin.adresseCabinet.localite}}, {{ medecin.adresseCabinet.ville }}</span> 
                        </p>
                        <a *ngIf="!medecin.favorite" class="btn btn-favorite" style="color: #21726b;float: right;" (click)="toggleFavorite(medecin)">
                            <i class="fa-regular fa-heart" style="font-size: 1.7rem;"></i>
                        </a>
                        <a *ngIf="medecin.favorite" class="btn btn-favorite" style="color: #21726b;float: right;" (click)="toggleFavorite(medecin)">
                            <i class="fa-solid fa-heart" style="font-size: 1.7rem;"></i>
                        </a>
                    </div>
                </div>
                
            </div>
        </div>
        <div *ngIf="totalPages > 1" class="row justify-content-center">
            <div class="col-auto">
                <button class="btn btn-primary" (click)="previousPage()" [disabled]="currentPage === 1"><i class="fas fa-chevron-left"></i></button>
            </div>
            <div class="col-auto">
                <div class="btn-group">
                    <button class="btn btn-outline-primary ml-2" *ngFor="let page of getPageNumbers()" [class.active]="page === currentPage" (click)="goToPage(page)">{{ page }}</button>
                </div>
            </div>
            <div class="col-auto">
                <button class="btn btn-primary" (click)="nextPage()" [disabled]="currentPage === totalPages"><i class="fas fa-chevron-right"></i></button>
            </div>
        </div>
        <div *ngIf="totalPages == 0">
            <div class="card">
                <div class="card-body">
                    <p class="card-text-center" style="text-align: center;padding-bottom: 23px;padding-top: 45px;">
                        <span style="color: #21726B;"><b>Aucun médecin trouvé.</b></span>
                    </p>
                </div>
        </div>
        </div>
    </div>

<app-footer></app-footer>
