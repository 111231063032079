<app-header></app-header>
<div class="container-fluid p-sm-5 bg-light">
    <div class="row">
        <div class="col-lg-12">
            <ul class="breadcrumb float-md-right">
                <li class="breadcrumb-item"><a class="color-blue" routerLink="/medecin/accueil"><i class="fa-solid fa-house"></i>
                        Accueil</a>
                </li>
                <li class="breadcrumb-item"><a class="color-blue" routerLink="/medecin/patients-liste">
                        Patients</a>
                </li>
                <li class="breadcrumb-item active">Ajouter un patient</li>
            </ul>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-lg-12">
            <div class="card p-4" style="border: none;box-shadow: 0 8px 8px rgba(34, 34, 34, 0.03), 0 4px 16px rgba(34, 34, 34, 0.1);">
                <div class="card-body">
                    <form #f0="ngForm" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                        <div class="form-row mb-4">
                            <div class="col d-flex align-items-center">
                                <!-- Ajout de l'image avec une classe de Bootstrap pour la taille -->
                                <img src="./../../../assets/user.png" class="img-fluid" alt="Image" style="width: 130px; height: 130px;">

                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <div class="form-group">
                                    <!-- Suppression de la marge inférieure pour le champ -->
                                    <label>Numéro de fiche:<b style="color: red;"> *</b></label>
                                    <input type="texte" class="form-control" formControlName="numFiche" placeholder="N° de Fiche" onkeypress="return /[a-zA-Z0-9]/i.test(event.key)" [ngClass]="{ 'is-invalid': submitted && f.numFiche.errors }">
                                    <div *ngIf="submitted && f.numFiche.errors" class="invalid-feedback">
                                        <div *ngIf="f.numFiche.errors.required">{{champsObligatoire}}</div>
                                        <div *ngIf="f.numFiche.errors.maxlength">{{champMaxLength}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>Nom:<b style="color: red;"> *</b></label>
                                    <input type="text" class="form-control" formControlName="nom" [ngClass]="{ 'is-invalid': submitted && f.nom.errors }" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)">
                                    <div *ngIf="submitted && f.nom.errors" class="invalid-feedback">
                                        <div *ngIf="f.nom.errors.required">{{champsObligatoire}}</div>
                                        <div *ngIf="f.nom.errors.minlength">
                                            {{champMinLength}}
                                        </div>
                                        <div *ngIf="f.nom.errors.maxlength">
                                            {{champMaxLength}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>Prénom:<b style="color: red;"> *</b></label>
                                    <input type="text" class="form-control" formControlName="prenom" [ngClass]="{ 'is-invalid': submitted && f.prenom.errors }" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)">
                                    <div *ngIf="submitted && f.prenom.errors" class="invalid-feedback">
                                        <div *ngIf="f.prenom.errors.required">{{champsObligatoire}}</div>
                                        <div *ngIf="f.prenom.errors.minlength">
                                            {{champMinLength}}
                                        </div>
                                        <div *ngIf="f.prenom.errors.maxlength">
                                            {{champMaxLength}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <div class="form-group">
                                    <label>Date de naissance:<b style="color: red;"> *</b></label>
                                    <input type="date" class="form-control" formControlName="dateAnniversaire" max="{{today |date:'yyyy-MM-dd'}}" [ngClass]="{ 'is-invalid': submitted && f.dateAnniversaire.errors }">
                                    <div *ngIf="submitted && f.dateAnniversaire.errors" class="invalid-feedback">
                                        <div *ngIf="f.dateAnniversaire.errors.required">{{champsObligatoire}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>Email:</label>
                                    <input type="text" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" onkeypress="return /[a-zA-Z0-9 .@]/.test(event.key)">
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.email">{{invalidEmailAddress}}</div>
                                    </div>

                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>Téléphone:<b style="color: red;"> *</b></label>
                                    <ngx-intl-tel-input class="'form-control'" [enableAutoCountrySelect]="false" formControlName="tel" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Tunisia" [maxLength]="8" [phoneValidation]="true" [ngClass]="{ 'is-invalid': submitted && f.tel.errors }"></ngx-intl-tel-input>
                                    <div *ngIf="submitted && f.tel.errors" class="invalid-feedback">
                                        <div *ngIf="f.tel.errors.required">{{champsObligatoire}}</div>
                                    </div>
                                    <div *ngIf="registerForm.value.tel && !f0.form.controls['tel'].invalid == false" style="color: red;">
                                        {{invalidNumTelephone}} <i class="bi bi-x"></i>
                                    </div>
                                    <div *ngIf=" registerForm.value.tel && !f0.form.controls['tel'].invalid == true" style="color: green;">
                                        Numéro de téléphone validé <i class="bi bi-check"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col">
                                <div class="form-group">
                                    <label>Profession:</label>
                                    <input type="text" class="form-control" formControlName="profession" onkeypress="return /[a-zA-ZàâäéèêëïîôöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)">
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>Groupe Sanguin:</label>
                                    <ng-select [items]="groupeSanguins" class="form-control " [closeOnSelect]="true" formControlName="groupeSanguin">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <div class="form-group">
                                    <label>Sexe:<b style="color: red;"> *</b></label>
                                    <ng-select [items]="Sexes" class="form-control " [closeOnSelect]="true" formControlName="sexe" [ngClass]="{ 'is-invalid': submitted && f.sexe.errors }">
                                    </ng-select>
                                    <div *ngIf="submitted && f.sexe.errors" class="invalid-feedback">
                                        <div *ngIf="f.sexe.errors.required">{{champsObligatoire}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>Statut familial:</label>
                                    <ng-select [items]="statusFamiliales" class="form-control " [closeOnSelect]="true" formControlName="statusFamilial">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="form-row" formGroupName="adresse">
                            <div class="col">
                                <div class="form-group">
                                    <label>Pays:</label>
                                    <ng-select [items]="list_contries" formControlName="pays" class="form-control input-custom" (change)="getPays()" [closeOnSelect]="true">
                                    </ng-select>
                                </div>
                            </div>

                            <div class="col">
                                <div *ngIf="registerForm.get('adresse.pays').value == 'Tunisie' " class="form-group">
                                    <label>Ville:</label>
                                    <ng-select [items]="governorates" formControlName="ville" class="form-control input-custom" [closeOnSelect]="true" (change)="getVille(registerForm.get('adresse.ville').value)">
                                    </ng-select>
                                </div>
                                <div *ngIf="registerForm.get('adresse.pays').value != 'Tunisie' || registerForm.get('adresse.pays').value == '' " class="form-group">
                                    <label>Ville:</label>
                                    <input type="text" class="form-control" formControlName="ville" onkeypress="return /[a-zA-ZàâäéèêëïîôöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)">
                                </div>
                            </div>

                            <div class="col">
                                <div *ngIf="registerForm.get('adresse.pays').value == 'Tunisie' " class="form-group">
                                    <label>Code Postal:</label>
                                    <ng-select [items]="codePostalList" formControlName="codePostal" class="form-control input-custom" [closeOnSelect]="true">
                                    </ng-select>
                                    <!-- <input type="text" class="form-control" formControlName="codePostal" [(ngModel)]="codePostalChoise"> -->
                                </div>
                                <div *ngIf="registerForm.get('adresse.pays').value != 'Tunisie' || registerForm.get('adresse.pays').value == '' " class="form-group">
                                    <label>Code Postal:</label>
                                    <input type="text" class="form-control" formControlName="codePostal" [(ngModel)]="codePostalChoise" onkeypress="return /[0-9]/.test(event.key)">
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label>Adresse:</label>
                                    <input type="text" class="form-control" formControlName="adresse" onkeypress="return /[a-zA-ZàâäéèêëïîôöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ, 0-9]/i.test(event.key)">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <div class="form-group">
                                    <label>APCI:</label>
                                    <ng-select [items]="apcis" formControlName="apci" class="form-control input-custom" [closeOnSelect]="true" multiple="true" [maxSelectedItems]="5">
                                    </ng-select>
                                    <div *ngIf="registerForm.value?.apci?.length > 4 " style="    font-size: .875em;
                                        color: #dc3545;margin-top: .25rem;">
                                        {{apciMaxElements}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row" formGroupName="orgSante">
                            <div class="col">
                                <div class="form-group">
                                    <label>Type d'organisme:</label>
                                    <ng-select [items]="typeOrgs" formControlName="typeOrg" class="form-control input-custom" [closeOnSelect]="true">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label class="fieldsTitle">Nom de l'établissement:</label>
                                    <input class="form-control" formControlName="nomEtablissement" name="nomEtablissement" onkeypress="return /[a-zA-ZàâäéèêëïîôöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)">
                                </div>

                            </div>
                        </div>
                        <div class="form-group text-right">
                            <button type="submit" class="btn btn-primary">Enregistrer</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>