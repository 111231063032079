import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MedecinService } from '../services/medecin/medecin.service';

@Component({
  selector: 'app-search-medecin',
  templateUrl: './search-medecin.component.html',
  styleUrls: ['./search-medecin.component.css']
})
export class SearchMedecinComponent implements OnInit {

  medecins: any;
  nomMedecin: any;
  specialiteMedecin: any;
  villeMedecin: any;
  currentPage: number = 1;
  totalPages: number = 0;
  totalPagesArray: number[] = [];
  constructor(private medecinService: MedecinService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    console.log("eeeee")
    // this.spinnerService.show();
    // setTimeout(() => {
    //   this.spinnerService.hide();
    // }, 2000); // 2 seconds
    this.route.queryParams.subscribe(params => {
      this.nomMedecin = params['prenom_nom'];
      this.specialiteMedecin = params['specialite'];
      this.villeMedecin = params['localisation'];
      if (this.villeMedecin && this.villeMedecin != undefined && this.villeMedecin.indexOf("é") > -1) {
        this.villeMedecin = this.villeMedecin.replace(/é/g, 'e');
      }
      if (this.villeMedecin && this.villeMedecin != undefined && this.villeMedecin.indexOf("è") > -1) {
        this.villeMedecin = this.villeMedecin.replace(/è/g, 'e');
      }


      // this.searchMedecins();
    });
  }

  //   searchMedecins(): void {
  //     this.medecinService.searchMedecin(this.nomMedecin, this.specialiteMedecin, this.villeMedecin, this.currentPage)
  //         .subscribe(
  //             (data: any) => {
  //                 this.medecins = data.medecins;
  //                 this.totalPages = data.totalPages;
  //                 this.totalPagesArray = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  //             },
  //             (error) => {
  //                 console.error(error);
  //             }
  //         );
  // }

  nextPage(): void {
    window.scrollTo(0, 0);
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      // this.searchMedecins();
    }
  }

  previousPage(): void {
    window.scrollTo(0, 0);
    if (this.currentPage > 1) {
      this.currentPage--;
      // this.searchMedecins();
    }
  }
  goToPage(page: number): void {
    window.scrollTo(0, 0);
    this.currentPage = page;
    // this.searchMedecins();
  }
  ngAfterViewInit(): void {
    // Faire défiler la fenêtre vers le haut au chargement du composant
    window.scrollTo(0, 0);
  }
  getPageNumbers(): number[] {
    const numButtonsToShow = 0; // Nombre de boutons de page à afficher
    let start = 1;
    let end = this.totalPages;

    if (this.totalPages > numButtonsToShow) {
      if (this.currentPage <= Math.ceil(numButtonsToShow / 2)) {
        start = 1;
        end = numButtonsToShow;
      } else if (this.currentPage >= this.totalPages - Math.floor(numButtonsToShow / 2)) {
        start = this.totalPages - numButtonsToShow + 1;
        end = this.totalPages;
      } else {
        start = this.currentPage - Math.floor(numButtonsToShow / 2);
        end = this.currentPage + Math.floor(numButtonsToShow / 2);
      }
    }

    // Vérifier si la première page est réellement la première dans la liste
    if (start > 1) {
      start = Math.max(1, start - 1); // Réduire d'un pour inclure la première page
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }

  getDirections(medecin: any): void {
    // Récupérer l'adresse du médecin
    const adresseMedecin = `${medecin.adresseCabinet.adresse}, ${medecin.adresseCabinet.localite}, ${medecin.adresseCabinet.ville}`;

    // Vérifier si le navigateur prend en charge la géolocalisation
    if (navigator.geolocation) {
      // Demander la position de l'utilisateur
      navigator.geolocation.getCurrentPosition((position) => {
        // Récupérer les coordonnées géographiques de l'utilisateur
        const userLatitude = position.coords.latitude;
        const userLongitude = position.coords.longitude;

        // Construire l'URL pour les directions
        const directionsURL = `https://www.google.com/maps/dir/${userLatitude},${userLongitude}/${encodeURIComponent(adresseMedecin)}`;

        // Ouvrir les directions dans un nouvel onglet
        window.open(directionsURL, '_blank');
      }, (error) => {
        console.error('Erreur de géolocalisation:', error);
        // En cas d'erreur, ouvrir les directions dans un nouvel onglet sans la position actuelle de l'utilisateur
        const directionsURL = `https://www.google.com/maps/dir//${encodeURIComponent(adresseMedecin)}`;
        window.open(directionsURL, '_blank');
      });
    } else {
      // Si la géolocalisation n'est pas prise en charge, ouvrir les directions dans un nouvel onglet sans la position actuelle de l'utilisateur
      const directionsURL = `https://www.google.com/maps/dir//${encodeURIComponent(adresseMedecin)}`;
      window.open(directionsURL, '_blank');
    }
  }

  toggleFavorite(medecin: any) {
    medecin.favorite = !medecin.favorite;
  }

  chat() {

  }
}
