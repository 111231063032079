import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { apciMaxElements, champMaxLength, champMinLength, champsObligatoire, invalidEmailAddress, invalidNumTelephone } from 'src/app/services/constants/messages';
import { Patient } from 'src/app/services/patient/patient.model';
import { PatientService } from 'src/app/services/patient/patient.service';
import postCode from 'src/app/services/constants/TN_Postal_codes.json';
import countries from 'src/app/services/constants/List_countries.json';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-update-patient',
  templateUrl: './update-patient.component.html',
  styleUrls: ['./update-patient.component.css']
})
export class UpdatePatientComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  patientId: string;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  Sexes = ['Homme', 'Femme']
  statusFamiliales = ['Célibataire', 'Marié(e)', 'Divorcé(e)', 'Veuf(ve)']
  groupeSanguins = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-']
  apcis = [
    'Affections coronariennes et leurs complications', 'Affections hypophysaires', 'Affections surrénaliennes', 'Cardiopathies congénitales et valvulopathies',
    'Cirrhoses et insuffisance hépatique', 'Diabète insulinodépendant ou non insulinodépendant', 'Dysthyroïdies', 'Epilepsie', 'Glaucome', 'HTA sévère', 'Hépatites chroniques actives',
    'Insuffisance cardiaque et troubles du rythme', 'Insuffisance respiratoire chronique', 'Insuffisance rénale chronique', 'Les maladies inflammatoires chroniques de l\'intestin',
    'Maladie de Parkinson', 'Maladies auto-immunes', 'Mucoviscidose', 'Phlébites', 'Psychoses et névroses', 'Rhumatismes inflammatoires chroniques', 'Sclérose en plaques',
    'Tuberculose active', 'Tumeurs et hémopathies malignes'
  ]
  typeOrgs = ['CNAM', 'CNSS']
  governorates: any[];
  delegs: any[];
  cites: any;
  codePostalChoise: any = "";
  today = new Date()
  // message d'erreur
  champsObligatoire = champsObligatoire
  champMinLength = champMinLength
  champMaxLength = champMaxLength
  invalidEmailAddress = invalidEmailAddress
  invalidNumTelephone = invalidNumTelephone
  apciMaxElements = apciMaxElements
  // end message d'erreur
  codePostalList: any = []
  list_contries = countries
  postal_codes = postCode
  medecin: any
  patient: any
  age: any
  constructor(
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public iziToast: Ng2IzitoastService,
    private authService: AuthService
  ) {
    let params: any = this.activatedRoute.snapshot.params;
    this.patientId = params.id;
    this.loadPatientData(this.patientId)
  }

  ngOnInit(): void {
    this.governorates = [...new Set(this.postal_codes.map(item => item.Gov))];
    this.registerForm = this.formBuilder.group({
      numFiche: ['', [Validators.required, Validators.maxLength(25),]],
      nom: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(3)]],
      prenom: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(3)]],
      dateAnniversaire: ['', Validators.required],
      email: ['', Validators.email],
      tel: ['', Validators.required],
      profession: ['',],
      groupeSanguin: ['',],
      sexe: ['', Validators.required],
      statusFamilial: ['',],
      ville: ['', [Validators.minLength(3), Validators.maxLength(25)]],
      pays: [''],
      codePostal: ['', [Validators.maxLength(4)]],
      adresse: this.formBuilder.group({
        adresse: ['', [Validators.minLength(3), Validators.maxLength(500)]],
        ville: ['', [Validators.minLength(3), Validators.maxLength(25)]],
        pays: [''],
        codePostal: ['', [Validators.maxLength(4)]],
      }),
      apci: [[],],
      orgSante: this.formBuilder.group({
        typeOrg: [''],
        nomEtablissement: ['', [Validators.maxLength(25)]],
        email: ['',],
        tel: ['+216'],
        adresse: this.formBuilder.group({
          ville: ['', [Validators.maxLength(25)]],
          codePostal: ['', [Validators.maxLength(4)]],
          adresse: ['', Validators.maxLength(500)],
        }),
      }),
    },);
    if (localStorage.getItem('medecin')) {
      this.authService.getProfileMedecin().subscribe((profile: any) => {
        this.medecin = profile.medecin;
        if (!this.medecin.roles.gestionPatient) {
          this.router.navigate(['/']);
          return false;
        }
      },
        err => {
          return false;
        });
    }

  }



  get f() { return this.registerForm.controls; }

  loadPatientData(patientId: string) {
    this.patientService.getPatientByID(patientId).subscribe(
      (patient: any) => {
        this.patient = patient
        this.registerForm.patchValue(patient);
      },
      err => {
        this.router.navigate(['/medecin/patients-liste'])
        return false;
      }
    );
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    if (this.registerForm.value.tel) {
      this.registerForm.value.tel = this.registerForm.value.tel.internationalNumber
    }
    var today = new Date();
    var birthDate = new Date(this.registerForm.value.dateAnniversaire);
    this.age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      this.age--;
    }
    this.registerForm.value.age = this.age;
    let patientModified = { ...this.patient, ...this.registerForm.value }
    this.patientService.editProfil(patientModified).subscribe((patient: any) => {
      this.iziToast.show({
        message: 'Bien modifié',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: false,
      });
      this.router.navigate(['/medecin/consultation-liste', patient._id]);
    });

  }

  getPays() {
    this.registerForm.get('adresse.ville').patchValue('');
    this.registerForm.get('adresse.codePostal').patchValue('');
    this.registerForm.get('adresse.adresse').patchValue('');
    this.codePostalChoise = ""
  }

  getVille(cov) {
    this.registerForm.get('adresse.codePostal').patchValue('');
    let listdelegs = this.postal_codes.filter(x => x.Gov === cov)
    // Extraction des noms des délégations
    const delegsNames = listdelegs.map(deleg => deleg.Deleg);
    // Élimination des doublons en utilisant un Set
    this.delegs = [...new Set(delegsNames)];
    this.codePostalList = [...new Set(listdelegs.map(deleg => deleg.zip))]
  }

}
