import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
  transform(array: any[], field: string): any[] {
    if (!Array.isArray(array)) {
      return [];
    }

    // Separate rdvs based on resTime
    const rdvsWithNonZeroTime = array.filter(rdv => rdv.resTime !== '00:00');
    const rdvsWithZeroTime = array.filter(rdv => rdv.resTime === '00:00');

    // Sort rdvs with non-zero resTime by their start time
    rdvsWithNonZeroTime.sort((a: any, b: any) => {
      return this.compareTimes(a.start, b.start);
    });

    // Sort rdvs with zero resTime by their start time
    rdvsWithZeroTime.sort((a: any, b: any) => {
      return this.compareTimes(a.start, b.start);
    });

    // Combine sorted rdvs
    return rdvsWithNonZeroTime.concat(rdvsWithZeroTime);
  }

  // Helper method to compare times
  private compareTimes(startA: string, startB: string): number {
    if (!startA || !startB) return 0;

    const timeA = new Date(`1970-01-01T${startA}:00`).getTime();
    const timeB = new Date(`1970-01-01T${startB}:00`).getTime();

    return timeA - timeB;
  }
}
