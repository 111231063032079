<app-header></app-header>
<div class="container-fluid p-sm-5 bg-light vh-100">
    <div class="row">
        <div class="col-lg-12">
            <ul class="breadcrumb float-md-right">
                <li class="breadcrumb-item"><a class="color-blue" routerLink="/medecin/accueil"><i class="fa-solid fa-house"></i>
                        Accueil</a>
                </li>
                <li class="breadcrumb-item active">Patient</li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-12">
            <div class="row">
                <h4>Liste des patients</h4>
                <button class="circular-button ml-2" (click)="searchPatientWithID()"><i class="fa-regular fa-id-badge"></i></button>
            </div>
        </div>
    </div>

    <div class="col-md-12">
        <div class="row justify-content-end">
            <div class="col-auto">
                <button routerLink="/medecin/nouveauPatient" class="btn btn-primary">Ajouter un patient</button>
            </div>
            <div class="col-auto">
                <button class="btn btn-primary" (click)="generateEXCELpatient()">Exporter</button>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-12 mb-3 mb-md-0">
            <div class="form-group">
                <div class="col row ">
                    <input type="text" class="col form-control mr-2" placeholder="N° Fiche" [(ngModel)]="numFicheToFiltred" (input)="filter()" onkeypress="return /[a-zA-Z0-9]/.test(event.key)">
                    <input type="text" class="col form-control mr-2" placeholder="Nom" [(ngModel)]="nomToFiltred" (input)="filter()" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)">
                    <input type="text" class="col form-control mr-2" placeholder="Prénom" [(ngModel)]="prenomToFiltred" (input)="filter()" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)">
                    <input type="text" class="col form-control mr-2" placeholder="Profession" [(ngModel)]="professionToFiltred" (input)="filter()" onkeypress="return /[a-zA-ZàâäéèêëïîôöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)">
                    <input type="text" class="col form-control mr-2" placeholder="Âge" [(ngModel)]="ageToFiltred" (input)="filter()" onkeypress="return /[0-9]/.test(event.key)">
                    <button (click)="resetFilters()" class="btn btn-primary p-2 mr-1">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                            <style>
                                svg {
                                    fill: #ffffff
                                }
                            </style>
                            <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>

    </div>

    <div class="row mt-4">
        <div class="col-12">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr class="ligne-paire text-center">
                            <th scope="col" style="color:#589590;"></th>
                            <th scope="col" style="color:#589590;">N° de fiche</th>
                            <th scope="col" style="color:#589590;">Nom</th>
                            <th scope="col" style="color:#589590;">Prénom</th>
                            <th scope="col" style="color:#589590;">Profession</th>
                            <th scope="col" style="color:#589590;">Âge</th>
                            <th scope="col" style="color:#589590;">Actions</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let i of patients | paginate
                        : {
                            itemsPerPage: tableSize,
                            currentPage: page,
                            totalItems: count
                        }; let k = index">
                        <tr [class.ligne-paire]="k % 2 !== 0" [class.ligne-impaire]="k % 2 === 0" class="text-center" style="cursor: pointer;">
                            <td [routerLink]="['/medecin/consultation-liste', i._id]">
                                <div class="rounded-circle overflow-hidden mx-auto" style="width: 30px; height: 30px;">
                                    <img src="../../assets/user.png" alt="Image" class="img-fluid">
                                </div>
                            </td>
                            <td [routerLink]="['/medecin/consultation-liste', i._id]">{{i.numFiche}}</td>
                            <td [routerLink]="['/medecin/consultation-liste', i._id]">{{i.nom}}</td>
                            <td [routerLink]="['/medecin/consultation-liste', i._id]">{{i.prenom}}</td>
                            <td [routerLink]="['/medecin/consultation-liste', i._id]">{{i.profession}}</td>

                            <td *ngIf="!i.age || i.age == ''" [routerLink]="['/medecin/consultation-liste', i._id]">{{ calculateAge(i.dateAnniversaire) }}</td>
                            <td *ngIf="i.age != '' " [routerLink]="['/medecin/consultation-liste', i._id]">{{i.age}}</td>
                            <td>
                                <div>
                                    <a (click)="selectMotif()"><i class="fa-solid fa-file-circle-plus mr-1" title="Ajouter une consultation"></i></a>
                                    <a (click)="ViewHistory()"><i class="fa-solid fa-clock-rotate-left mr-1" title="Historique des consultations"></i></a>
                                    <a [routerLink]="['/medecin/consultation-liste', i._id]"><i class="fa-regular fa-eye mr-1" title="Détails patient"></i></a>
                                    <a (click)="deletPatient(i)"><i class="fa-solid fa-trash" title="Supprimer un patient"></i></a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="patients && patients.length > 10 " class=" border-0 mt-4">
                    <div class="d-flex justify-content-center">
                        <pagination-controls class="my-pagination" previousLabel="Précédent" nextLabel="Suivant" (pageChange)="onTableDataChange($event)">
                        </pagination-controls>
                    </div>
                </div>

                <div *ngIf="patients && patients.length == 0" class="table-responsive" style="text-align: center;color: #666;">
                    <p style="padding-bottom: 9rem; padding-top: 9rem ">
                        Aucun patient n'est trouvé
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>


<app-footer></app-footer>