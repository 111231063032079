<div class="modal-header">
  <h4 class="modal-title">Rechercher un patient</h4>
  <a class="exit" aria-label="Close" (click)="onReset()">
    <i class="fa-regular fa-circle-xmark"></i>
  </a>
</div>
<div class="modal-body">
  <div>
    <form [formGroup]="patientForm">
      <div *ngIf="!affectationPatient">
        <p style="color: #21726B; font-weight: 600;">Rechercher un patient inscrit sur Medivisto</p>
        <div class="form-row align-items-end">
          <!-- Champ de texte pour le nom du patient -->
          <div class="form-group col-md-5">
            <label for="patientId" style="color: #21726B">ID Medivisto</label>
            <input type="text" class="form-control" id="patientId" formControlName="patientId" placeholder="Entrez l'id du patient">
          </div>
          <!-- Champ de date -->
          <div class="form-group col-md-5">
            <label for="patientDOB" style="color: #21726B">Date de naissance</label>
            <input type="date" [max]="today | date: 'yyyy-MM-dd'" class="form-control" id="patientDOB" formControlName="patientDOB">
          </div>
          <!-- Bouton sur la même ligne -->
          <div class="form-group col-md-2">
            <button class="circular-button ml-2" *ngIf="isButtonVisible()" (click)="onSearch()"><i class="fa-solid fa-magnifying-glass"></i></button>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center ">
        <div class="card col-12 p-3 text-center" *ngIf="searchedPatient">
          <h5 class="card-title" style="color: #21726B;">{{ searchedPatient.nom }} {{ searchedPatient.prenom }}</h5>
          <div class="d-inline-block">
            <span class="badge bg-green p-1">{{ searchedPatient.profession }}</span>
          </div>
          <p class="card-text mt-2" style="color: #21726B;">{{ searchedPatient.dateAnniversaire |date:"dd/MM/yyyy" }}</p>
          <div>
            <button (click)="ajoutMatching()" class="btn btn-primary">
              <i class="fa-solid fa-user-plus"></i> Ajouter à mes patients
            </button>
            <button *ngIf="!affectationPatient" (click)="affectPatient()" class="btn btn-primary ml-2">
              <i class="fa-solid fa-link"></i> Affecter à un patient
            </button>
            <button *ngIf="affectationPatient" (click)="initSearch()" class="btn btn-primary ml-2">
              <i class="fa-solid fa-retweet"></i> Retourner
            </button>
          </div>
          <!--! tableau des patients dans affectation -->
          <div class="row mt-5 mx-3" *ngIf="affectationPatient">
            <input id="search-input" type="search" #myInput class="form-control" placeholder="Chercher un patient" [(ngModel)]="nom" [(ngModel)]="prenom" [(ngModel)]="numFiche" [(ngModel)]="age" (input)="search()" [ngModelOptions]="{standalone: true}" />
            <div class="mt-2">
              <div [ngClass]="sizeContentListPatients > 5 ? 'scroller' : 'scroller2'" class="table-responsive">
                <div>
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="text-center" scope="col" style="color:#589590;">N° de fiche</th>
                        <th class="text-center" scope="col" style="color:#589590;">Nom</th>
                        <th class="text-center" scope="col" style="color:#589590;">Prénom</th>
                        <th class="text-center" scope="col" style="color:#589590;">Âge</th>
                        <th class="text-center" scope="col" style="color:#589590;"></th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let pat of patients; let k = index">
                      <tr [class.ligne-paire]="k % 2 !== 0" [class.ligne-impaire]="k % 2 === 0">
                        <td class="text-center">{{ pat.numFiche }}</td>
                        <td class="text-center">{{ pat.nom }}</td>
                        <td class="text-center">{{ pat.prenom }}</td>
                        <td class="text-center">{{ pat.age }}</td>
                        <td class="text-center" (click)="patientToAffect(pat)" style="cursor: pointer; color: #21726b;" title="Affecter ce patient"><i class="fa-solid fa-link"></i></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div *ngIf="patients&&patients.length === 0" class="card w-100 h-100">
                  <div class="card-body d-flex align-items-center justify-content-center">
                    <p style="color: #589590; font-weight: bold;font-size: 1.3rem;">Aucun patient n'est trouvé.</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <!-- !Méssage de validation -->
          <div class="col justify-content-start" *ngIf="validationSection && patientToValidate &&affectationPatient">
            <p>Voulez-vous valider l'affectation du patient inscrit avec:</p>
            <p><strong class="informationColor">Numéro de fiche :</strong> {{patientToValidate.numFiche}}</p>
            <p><strong class="informationColor">Nom :</strong> {{patientToValidate.nom}}</p>
            <p><strong class="informationColor">Prénom :</strong> {{patientToValidate.prenom}}</p>
            <p><strong class="informationColor">Date de naissance suivante :</strong> {{patientToValidate.dateAnniversaire | date: "dd/MM/yyyy"}}</p>
          </div>
          <div *ngIf="validationSection && patientToValidate &&affectationPatient">
            <button class="btn btn-primary" (click)="onReset()">
              <i class="fa-regular fa-circle-xmark"></i> Non
            </button>
            <button (click)="ValidteAffectPatient()" class="btn btn-primary ml-2">
              <i class="fa-solid fa-link"></i> Oui
            </button>

          </div>
        </div>
        <div class="card col-12 p-3 text-center" *ngIf="searchedPatient==undefined">
          <p style="color: #21726B; font-style: italic;font-weight: 500;">Aucun patient trouvé.</p>
        </div>

      </div>

      <!-- <div class="d-flex justify-content-center mt-4">
        <div class="text-center">
          <button type="button" class="btn btn-secondary mr-2" (click)="onReset()">Annuler</button>
          <button type="submit" class="btn btn-primary">Sauvegarder</button>
        </div>
      </div> -->
    </form>
  </div>
</div>