export function  tri(a,b)
{
 if (a.name < b.name) return -1;
 else if (a.name == b.name) return 0;
 else return 1;
}
export const pays = [
  {"name": "Afghanistan"},
  {"name": "Albanie"},
  {"name": "Antarctique"},
  {"name": "Algérie"},
  {"name": "Samoa Américaines"},
  {"name": "Andorre"},
  {"name": "Angola"},
  {"name": "Antigua-et-Barbuda"},
  {"name": "Azerbaïdjan"},
  {"name": "Argentine"},
  {"name": "Australie"},
  {"name": "Autriche"},
  {"name": "Bahamas"},
  {"name": "Bahreïn"},
  {"name": "Bangladesh"},
  {"name": "Arménie"},
  {"name": "Barbade"},
  {"name": "Belgique"},
  {"name": "Bermudes"},
  {"name": "Bhoutan"},
  {"name": "Bolivie"},
  {"name": "Bosnie-Herzégovine"},
  {"name": "Botswana"},
  {"name": "Île Bouvet"},
  {"name": "Brésil"},
  {"name": "Belize"},
  {"name": "Territoire Britannique de l'Océan Indien"},
  {"name": "Îles Salomon"},
  {"name": "Îles Vierges Britanniques"},
  {"name": "Brunéi Darussalam"},
  {"name": "Bulgarie"},
  {"name": "Myanmar"},
  {"name": "Burundi"},
  {"name": "Bélarus"},
  {"name": "Cambodge"},
  {"name": "Cameroun"},
  {"name": "Canada"},
  {"name": "Cap-vert"},
  {"name": "Îles Caïmanes"},
  {"name": "République Centrafricaine"},
  {"name": "Sri Lanka"},
  {"name": "Tchad"},
  {"name": "Chili"},
  {"name": "Chine"},
  {"name": "Taïwan"},
  {"name": "Île Christmas"},
  {"name": "Îles Cocos (Keeling)"},
  {"name": "Colombie"},
  {"name": "Comores"},
  {"name": "Mayotte"},
  {"name": "République du Congo"},
  {"name": "République Démocratique du Congo"},
  {"name": "Îles Cook"},
  {"name": "Costa Rica"},
  {"name": "Croatie"},
  {"name": "Cuba"},
  {"name": "Chypre"},
  {"name": "République Tchèque"},
  {"name": "Bénin"},
  {"name": "Danemark"},
  {"name": "Dominique"},
  {"name": "République Dominicaine"},
  {"name": "Équateur"},
  {"name": "El Salvador"},
  {"name": "Guinée Équatoriale"},
  {"name": "Éthiopie"},
  {"name": "Érythrée"},
  {"name": "Estonie"},
  {"name": "Îles Féroé"},
  {"name": "Îles (malvinas) Falkland"},
  {"name": "Géorgie du Sud et les Îles Sandwich du Sud"},
  {"name": "Fidji"},
  {"name": "Finlande"},
  {"name": "Îles Åland"},
  {"name": "France"},
  {"name": "Guyane Française"},
  {"name": "Polynésie Française"},
  {"name": "Terres Australes Françaises"},
  {"name": "Djibouti"},
  {"name": "Gabon"},
  {"name": "Géorgie"},
  {"name": "Gambie"},
  {"name": "Territoire Palestinien Occupé"},
  {"name": "Allemagne"},
  {"name": "Ghana"},
  {"name": "Gibraltar"},
  {"name": "Kiribati"},
  {"name": "Grèce"},
  {"name": "Groenland"},
  {"name": "Grenade"},
  {"name": "Guadeloupe"},
  {"name": "Guam"},
  {"name": "Guatemala"},
  {"name": "Guinée"},
  {"name": "Guyana"},
  {"name": "Haïti"},
  {"name": "Îles Heard et Mcdonald"},
  {"name": "Saint-Siège (état de la Cité du Vatican)"},
  {"name": "Honduras"},
  {"name": "Hong-Kong"},
  {"name": "Hongrie"},
  {"name": "Islande"},
  {"name": "Inde"},
  {"name": "Indonésie"},
  {"name": "République Islamique d'Iran"},
  {"name": "Iraq"},
  {"name": "Irlande"},
  {"name": "Israël"},
  {"name": "Italie"},
  {"name": "Côte d'Ivoire"},
  {"name": "Jamaïque"},
  {"name": "Japon"},
  {"name": "Kazakhstan"},
  {"name": "Jordanie"},
  {"name": "Kenya"},
  {"name": "République Populaire Démocratique de Corée"},
  {"name": "République de Corée"},
  {"name": "Koweït"},
  {"name": "Kirghizistan"},
  {"name": "République Démocratique Populaire Lao"},
  {"name": "Liban"},
  {"name": "Lesotho"},
  {"name": "Lettonie"},
  {"name": "Libéria"},
  {"name": "Jamahiriya Arabe Libyenne"},
  {"name": "Liechtenstein"},
  {"name": "Lituanie"},
  {"name": "Luxembourg"},
  {"name": "Macao"},
  {"name": "Madagascar"},
  {"name": "Malawi"},
  {"name": "Malaisie"},
  {"name": "Maldives"},
  {"name": "Mali"},
  {"name": "Malte"},
  {"name": "Martinique"},
  {"name": "Mauritanie"},
  {"name": "Maurice"},
  {"name": "Mexique"},
  {"name": "Monaco"},
  {"name": "Mongolie"},
  {"name": "République de Moldova"},
  {"name": "Montserrat"},
  {"name": "Maroc"},
  {"name": "Mozambique"},
  {"name": "Oman"},
  {"name": "Namibie"},
  {"name": "Nauru"},
  {"name": "Népal"},
  {"name": "Pays-Bas"},
  {"name": "Antilles Néerlandaises"},
  {"name": "Aruba"},
  {"name": "Nouvelle-Calédonie"},
  {"name": "Vanuatu"},
  {"name": "Nouvelle-Zélande"},
  {"name": "Nicaragua"},
  {"name": "Niger"},
  {"name": "Nigéria"},
  {"name": "Niué"},
  {"name": "Île Norfolk"},
  {"name": "Norvège"},
  {"name": "Îles Mariannes du Nord"},
  {"name": "Îles Mineures Éloignées des États-Unis"},
  {"name": "États Fédérés de Micronésie"},
  {"name": "Îles Marshall"},
  {"name": "Palaos"},
  {"name": "Pakistan"},
  {"name": "Panama"},
  {"name": "Papouasie-Nouvelle-Guinée"},
  {"name": "Paraguay"},
  {"name": "Pérou"},
  {"name": "Philippines"},
  {"name": "Pitcairn"},
  {"name": "Pologne"},
  {"name": "Portugal"},
  {"name": "Guinée-Bissau"},
  {"name": "Timor-Leste"},
  {"name": "Porto Rico"},
  {"name": "Qatar"},
  {"name": "Réunion"},
  {"name": "Roumanie"},
  {"name": "Fédération de Russie"},
  {"name": "Rwanda"},
  {"name": "Sainte-Hélène"},
  {"name": "Saint-Kitts-et-Nevis"},
  {"name": "Anguilla"},
  {"name": "Sainte-Lucie"},
  {"name": "Saint-Pierre-et-Miquelon"},
  {"name": "Saint-Vincent-et-les Grenadines"},
  {"name": "Saint-Marin"},
  {"name": "Sao Tomé-et-Principe"},
  {"name": "Arabie Saoudite"},
  {"name": "Sénégal"},
  {"name": "Seychelles"},
  {"name": "Sierra Leone"},
  {"name": "Singapour"},
  {"name": "Slovaquie"},
  {"name": "Viet Nam"},
  {"name": "Slovénie"},
  {"name": "Somalie"},
  {"name": "Afrique du Sud"},
  {"name": "Zimbabwe"},
  {"name": "Espagne"},
  {"name": "Sahara Occidental"},
  {"name": "Soudan"},
  {"name": "Suriname"},
  {"name": "Svalbard etÎle Jan Mayen"},
  {"name": "Swaziland"},
  {"name": "Suède"},
  {"name": "Suisse"},
  {"name": "République Arabe Syrienne"},
  {"name": "Tadjikistan"},
  {"name": "Thaïlande"},
  {"name": "Togo"},
  {"name": "Tokelau"},
  {"name": "Tonga"},
  {"name": "Trinité-et-Tobago"},
  {"name": "Émirats Arabes Unis"},
  {"name": "Tunisie"},
  {"name": "Turquie"},
  {"name": "Turkménistan"},
  {"name": "Îles Turks et Caïques"},
  {"name": "Tuvalu"},
  {"name": "Ouganda"},
  {"name": "Ukraine"},
  {"name": "L'ex-République Yougoslave de Macédoine"},
  {"name": "Égypte"},
  {"name": "Royaume-Uni"},
  {"name": "Île de Man"},
  {"name": "République-Unie de Tanzanie"},
  {"name": "États-Unis"},
  {"name": "Îles Vierges des États-Unis"},
  {"name": "Burkina Faso"},
  {"name": "Uruguay"},
  {"name": "Ouzbékistan"},
  {"name": "Venezuela"},
  {"name": "Wallis et Futuna"},
  {"name": "Samoa"},
  {"name": "Yémen"},
  {"name": "Serbie-et-Monténégro"},
  {"name": "Zambie"}
]
export const times = [
               "00:00", "00:15",
               "00:30", "00:45",
               "01:00", "01:15",
               "01:30", "01:45",
               "02:00", "02:15",
               "02:30", "02:45",
               "03:00", "03:15",
               "03:30", "03:45",
               "04:00", "04:15",
               "04:30", "04:45",
               "05:00", "05:15",
               "05:30", "05:45",
               "06:00", "06:15",
               "06:30", "06:45",
               "07:00", "07:15",
               "07:30", "07:45",
               "08:00", "08:15",
               "08:30", "08:45",
               "09:00", "09:15",
               "09:30", "09:45",
               "10:00", "10:15",
               "10:30", "10:45",
               "11:00", "11:15",
               "11:30", "11:45",
               "12:00", "12:15",
               "12:30", "12:45",
               "13:00", "13:15",
               "13:30", "13:45",
               "14:00", "14:15",
               "14:30", "14:45",
               "15:00", "15:15",
               "15:30", "15:45",
               "16:00", "16:15",
               "16:30", "16:45",
               "17:00", "17:15",
               "17:30", "17:45",
               "18:00", "18:15",
               "18:30", "18:45",
               "19:00", "19:15",
               "19:30", "19:45",
               "20:00", "20:15",
               "20:30", "20:45",
               "21:00", "21:15",
               "21:30", "21:45",
               "22:00", "22:15",
               "22:30", "22:45",
               "23:00", "23:15",
               "23:30", "23:45"
]
