
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';

@Injectable({
    providedIn: 'root'
})
export class ConsultationService {

    constructor(private http: HttpClient) {
    }
    //get patients by medecin
    getAllPatientsByMedecin(id) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.get(_getURL(API_CONFIG.patients) + '/medecin/' + id, { headers: headers })

    }
    getAllPatientsByCreatedMedecin(id) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.get(_getURL(API_CONFIG.patients) + '/medecin/createdtByMedecin/' + id, { headers: headers })

    }
    // get consumltations d'un medecin by year
    getAllConsultationYearByMedecin(medecinId, year) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.get(_getURL(API_CONFIG.consultation) + '/get/by/year/' + medecinId + '/' + year, { headers: headers })

    }
    // get consumltations d'un medecin by year
    getAllExConsultationYearByMedecin(medecinId, year) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.get(_getURL(API_CONFIG.consultation) + '/get/by/ex/year/' + medecinId + '/' + year, { headers: headers })

    }
    //get consultations by patient
    getAllConsultationsByPatient(id) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.get(_getURL(API_CONFIG.consultation) + '/patient/' + id, { headers: headers })

    }
    //get consultations by patient
    geetConsultationById(id) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.get(_getURL(API_CONFIG.consultation) + '/' + id, { headers: headers })

    }
    //get documents by consultation
    getAllDocumentsByConsultation(id) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.get(_getURL(API_CONFIG.document) + '/consultation/' + id, { headers: headers })

    }
    //save patient qui va prendre une consultation
    registerPatient(patient) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.post(_getURL(API_CONFIG.patients), patient, { headers: headers })

    }
    deleteconsultation(idConsultation) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.delete(_getURL(API_CONFIG.consultation) + '/' + idConsultation, { headers: headers })

    }
    AjoutConsultation(consultation) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.post(_getURL(API_CONFIG.consultation), consultation, { headers: headers })

    }
    // verify numero de fiche de patients
    verifyNumeroFichePatient(patient) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.post(_getURL(API_CONFIG.patients) + '/verifyNumFiche', patient, { headers: headers })

    }
    onUpdateConsultation(consultation) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.put(_getURL(API_CONFIG.consultation) + '/' + consultation._id, consultation, { headers: headers })

    }
    getPatientByidentifiantMedivisto(identifiant) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.get(_getURL(API_CONFIG.patients) + '/identifiant/unique/medivisto/length/' + identifiant, { headers: headers })

    }
}
