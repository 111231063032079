import { Ng2IzitoastService } from 'ng2-izitoast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MotifConsultationComponent } from '../popups/motif-consultation/motif-consultation.component';
import { HistoriqueConsultationComponent } from '../popups/historique-consultation/historique-consultation.component';
import { SuppressionComponent } from '../popups/suppression/suppression.component';
import { NgSelectConfig } from '@ng-select/ng-select';
import { PatientService } from '../services/patient/patient.service';
import { AuthService } from '../services/auth/auth.service';
import { ConsultationService } from '../services/consultation/consultation.service';
import { MedecinService } from '../services/medecin/medecin.service';
import * as XLSX from 'xlsx';
import { PopupSearchPatientComponent } from '../popups/search-patient/popup-search-patient.component';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css'],
  providers: [NgbActiveModal]
})
export class PatientComponent implements OnInit {
  page: number = 1;
  patients: any[] = [];
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  count: number = 0;
  copyListPatients: any[];

  prenomToFiltred: any;
  nomToFiltred: any;
  professionToFiltred: any;
  numFicheToFiltred: any;
  ageToFiltred: any;
  medecinId: string | null;
  selectedPatient: any;
  patient: any
  medecin: any
  medecinUsedId: any
  secretaire: any
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private config: NgSelectConfig,
    private patientService: PatientService,
    private authService: AuthService,
    private iziToast: Ng2IzitoastService,
    private consultationService: ConsultationService,
    private medecinService: MedecinService
  ) {
    this.config.notFoundText = "Aucun élément trouvé"
  }


  ngOnInit(): void {
    if (localStorage.getItem('medecin')) {

      this.authService.getProfileMedecin().subscribe((profile: any) => {
        this.medecin = profile.medecin;
        if (!this.medecin.roles.gestionPatient) {
          this.router.navigate(['/notFound']);
          return false;
        }
        var medecinId = this.medecin._id;
        this.medecinUsedId = medecinId
        this.getPatients()
      },
        err => {
          return false;
        });
    }
    if (localStorage.getItem('secretaire')) {
      this.authService.getProfileSecretaire().subscribe((profile: any) => {
        this.secretaire = profile.secretaire;
        if (!this.secretaire.roles.gestionPatient) {
          this.router.navigate(['/notFound']);;
          return false;
        }
        var medecinId = this.secretaire.medecinId;
        this.medecinUsedId = medecinId
        this.getPatients()
      },
        err => {
          return false;
        });
    }
    //this.idmedecinTraitant = medecinId;

  }
  getPatients() {
    this.consultationService.getAllPatientsByMedecin(this.medecinUsedId)
      .subscribe((patients: any[]) => {
        this.patients = patients;
        if (this.patients) {

          this.patients = patients.sort(this.triapatients);

          this.copyListPatients = this.patients
        }
      });
  }
  onTableDataChange(event: any) {
    this.page = event;

  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
  }

  calculateAge(dateOfBirth) {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age.toString();
  }
  deletPatient(patient) {
    const modalRef = this.modalService.open(SuppressionComponent);
    modalRef.componentInstance.patient = patient;
    modalRef.componentInstance.name = "patient";
    modalRef.result.then((result) => {
      if (result === 'confirm') {
        this.removePatient(patient);
      }
    }, (reason) => {
      // Handle dismiss case
    });
  }
  //supprimer un patient
  removePatient(patient: any) {
    var medecinId = this.medecinId;
    if (patient && patient.listMedecinsId && patient.listMedecinsId.length > 0) {
      patient.listMedecinsId.splice(patient.listMedecinsId.indexOf(medecinId), 1);
    }
    this.patients.splice(this.patients.indexOf(patient), 1);
    this.patientService.editlistMedecinsPatient(patient).subscribe(() => {
      this.iziToast.show({
        message: 'Bien supprimé',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    }, error => {
      console.log('Error removing patient:', error);
    });
  }
  updatePatient(id) {
    this.router.navigate(['/medecin/patient', id]);
  }
  selectMotif() {
    let modalRef
    modalRef = this.modalService.open(MotifConsultationComponent);
  }

  ViewHistory() {
    let modalRef
    modalRef = this.modalService.open(HistoriqueConsultationComponent);
  }

  filter() {
    this.patients = this.copyListPatients;
    let filteredPatients = [...this.copyListPatients];

    // Convert filter criteria to lowercase
    const numFicheilterLower = this.numFicheToFiltred ? this.numFicheToFiltred.toLowerCase() : '';
    const prenomFilterLower = this.prenomToFiltred ? this.prenomToFiltred.toLowerCase() : '';
    const nomFilterLower = this.nomToFiltred ? this.nomToFiltred.toLowerCase() : '';
    const professionFilterLower = this.professionToFiltred ? this.professionToFiltred.toLowerCase() : '';
    const ageFilterLower = this.ageToFiltred ? this.ageToFiltred.toLowerCase() : '';
    // const emailFilterLower = this.emailToFiltred ? this.emailToFiltred.toLowerCase() : '';
    // const telephoneFilterLower = this.telephoneToFiltred ? this.telephoneToFiltred : '';

    filteredPatients = this.patients.filter(item => {
      const numFicheFilter = numFicheilterLower ? (item.numFiche ? item.numFiche.toLowerCase().includes(numFicheilterLower) : false) : true;
      const nomFilter = nomFilterLower ? (item.nom ? item.nom.toLowerCase().includes(nomFilterLower) : false) : true;
      const prenomFilter = prenomFilterLower ? (item.prenom ? item.prenom.toLowerCase().includes(prenomFilterLower) : false) : true;
      const professionFilter = professionFilterLower ? (item.profession ? item.profession.toLowerCase().includes(professionFilterLower) : false) : true;
      const ageFilter = ageFilterLower ? ((item.age ? item.age.toLowerCase() : '').replace(/\s/g, '')).includes(ageFilterLower) : true;

      return numFicheFilter && nomFilter && prenomFilter && professionFilter && ageFilter
    });

    this.patients = filteredPatients;

    // Reset pagination
    this.page = 1;
  }
  resetFilters() {
    this.nomToFiltred = "";
    this.prenomToFiltred = "";
    this.numFicheToFiltred = "";
    this.professionToFiltred = "";
    this.ageToFiltred = "";

    this.filter();
  }
  searchPatientWithID() {
    let modalRef
    modalRef = this.modalService.open(PopupSearchPatientComponent);
    modalRef.componentInstance.medecinUsedId = this.medecinUsedId;
  }

  generateEXCELpatient() {
    if (this.medecinUsedId) {
      var currentDate = new Date()
      var day = currentDate.getDate()
      var month = currentDate.getMonth() + 1
      var year = currentDate.getFullYear()

      this.medecinService.getAllpatientsPDFExcel(this.medecinUsedId).subscribe((data: any) => {
        var readyToExport = data
        const workBook = XLSX.utils.book_new(); // create a new blank book
        const workSheet = XLSX.utils.json_to_sheet(readyToExport);

        XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
        XLSX.writeFile(workBook, 'Listes_patients_' + year + month + day + '.xlsx'); // initiate a file download in browser
      }, err => {
        return false;
      });
    } else {
      this.iziToast.show({
        message: 'Erreur !.',
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return false;
    }
  }
  triapatients(a, b) {
    if (a.created_at > b.created_at) return -1;
    else if (a.created_at == b.created_at) return 0;
    else return 1;
  }


}
