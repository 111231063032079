
<div class="modal-header">
    <h4 class="modal-title">Motif de consultation</h4>
    <a class="exit" aria-label="Close"(click)="onReset()"><i class="fa-regular fa-circle-xmark"></i></a>
</div>
<div class="modal-body">
    <div>
        <form class="">
            <div class="form-row">
                <div *ngIf="displayList == true" class="form-group col">
                    <label>Motif(s)
                        <a class="addMotif" (click)="addMotif()"><i class="fa-solid fa-square-plus"></i></a>

                    </label>
                    <ng-select [items]="listMotifs" class="form-control input-custom" [closeOnSelect]="true"
                        multiple="true">
                    </ng-select>
                    <!-- <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Champ obligatoire.</div>
                        </div> -->
                </div>

                <div *ngIf="displayList == false" class="form-group col">
                    <label>Nouveau motif</label>
                    <div class="form-group row">

                        <div class="col">
                            <input class="form-control" type="text" [(ngModel)]="newMotif"
                                [ngModelOptions]="{standalone: true}">
                        </div>
                        <div class="col">
                            
                            <button class="btn btn-secondary mr-2" (click)="cancelNotMotif()">Annuler</button>
                            <button (click)="saveMotif(newMotif)" class="btn btn-primary">Enregister</button>
                        </div>
                    </div>
                </div>





            </div>
            <!-- <div class="form-row">
                    <label>La/Les motif(s) selctionée(s) </label>
  
                    </div> -->

            <div *ngIf="displayList == true" class="d-flex justify-content-center mt-4">
                <!-- Ajout de la classe pour centrer -->
                <div class="text-center"> <!-- Ajout de la classe pour centrer -->
                    <button class="btn btn-secondary mr-2" (click)="onReset()">Annuler</button>
                    <button class="btn btn-primary">Sauvegarder</button>
                </div>
            </div>
        </form>
    </div>
</div>