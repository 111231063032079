<app-header></app-header>
<div class="container-fluid p-sm-5 bg-light vh-100">
    <div class="row">
        <div class="col-lg-12">
            <ul class="breadcrumb float-md-right">
                <li class="breadcrumb-item active"><span><i class="fa-solid fa-house"></i> Accueil</span></li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="mb-4">
                <h5>Mes Rendez-vous</h5>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 d-flex mb-2">
            <div [ngClass]="sizeContent > 8 ? 'scroller' : 'scroller2'" class="table-responsive">
                <div *ngIf="rdvs.length > 0">
                    <div>
                        <table class="table">
                            <!-- En-tête du tableau -->
                            <thead>
                                <tr class="ligne-paire">
                                    <th class="text-center" scope="col" style="color:#589590;">Patient</th>
                                    <th class="text-center" scope="col" style="color:#589590;">Motif</th>
                                    <th class="text-center" scope="col" style="color:#589590;">Heure</th>
                                    <th class="text-center" scope="col" style="color:#589590;">Statut</th>
                                </tr>
                            </thead>
                            <!-- Corps du tableau -->
                            <tbody *ngFor="let rdv of rdvs; let k = index">
                                <tr [class.ligne-paire]="k % 2 !== 0" [class.ligne-impaire]="k % 2 === 0" (click)="getInfoPatient(rdv._id)">
                                    <td class="text-center">{{ rdv.prenom_patient }} {{ rdv.nom_patient }}</td>
                                    <td class="text-center">{{ rdv.motif }}</td>
                                    <td class="text-center">{{ rdv.start | date:'HH:mm' }}</td>
                                    <td class="text-center"><input [id]="'cb' + k" [checked]="rdv.passed" type="checkbox"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- Définition du cas où il n'y a pas de rendez-vous -->
                <div *ngIf="rdvs.length === 0" class="card w-100 h-100">
                    <div class="card-body d-flex align-items-center justify-content-center">
                        <p style="color: #589590; font-weight: bold;font-size: 1.3rem;">La liste est actuellement vide. Aucune donnée n'est disponible pour le moment.</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="row mt-5">
        <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column">
            <div class="d-flex justify-content-between align-items-center mb-4">
                <h5>Patient</h5>
                <button routerLink="/medecin/nouveauPatient" class="btn btn-primary">Ajouter un patient</button>
            </div>
            <div [ngClass]="sizeContentListPatients > 8 ? 'scroller' : 'scroller2'" class="table-responsive">
                <div *ngIf="patients.length > 0">
                    <table class="table">
                        <thead>
                            <tr class="ligne-paire">
                                <th class="text-center" scope="col" style="color:#589590;">Nom et prénom</th>
                                <th class="text-center" scope="col" style="color:#589590;">Email</th>
                                <th class="text-center" scope="col" style="color:#589590;">Téléphone</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let pat of patients; let k = index">
                            <tr [class.ligne-paire]="k % 2 !== 0" [class.ligne-impaire]="k % 2 === 0">
                                <td class="text-center" [routerLink]="['/medecin/consultation-liste', pat._id]">{{ pat.prenom }} {{ pat.nom }}</td>
                                <td class="text-center" [routerLink]="['/medecin/consultation-liste', pat._id]">{{ pat.email }}</td>
                                <td class="text-center" [routerLink]="['/medecin/consultation-liste', pat._id]">{{ pat.tel }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="patients.length === 0" class="card w-100 h-100">
                    <div class="card-body d-flex align-items-center justify-content-center">
                        <p style="color: #589590; font-weight: bold;font-size: 1.3rem;">La liste est actuellement vide. Aucune donnée n'est disponible pour le moment.</p>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 d-flex flex-column">
            <div class="d-flex justify-content-between align-items-center mb-4">
                <h5>Statistiques de la semaine dernière</h5>
            </div>
            <!-- <div class="card">
                <div class="card-body">
                    <canvas id="myChart" style="width: 100%; height: 42vh;"></canvas>
                </div>
            </div> -->
            <div class="card w-100 h-100">
                <div class="card-body d-flex align-items-center justify-content-center">
                    <p style="color: #589590; font-weight: bold;font-size: 1.3rem;">Aucun Statistiques n'est disponible pour le moment.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>