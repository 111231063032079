import { Component, ViewChild, ElementRef, OnInit} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CalendarComponent } from 'ng-fullcalendar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { _getURL, API_CONFIG } from 'config/API_CONFIG';

import * as $ from 'jquery';
import * as moment from 'moment';
declare var jQuery: any;

import { RdvsService } from '../services/rdvs/rdvs.service';
import { AuthService } from '../services/auth/auth.service';
import { EventService } from '../services/event/event.service';
import { NotificationService } from '../services/notification/notification.service';
import { CalendarEvent } from 'angular-calendar';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../services/toast/toast.service';
import { times } from '../services/constants/pays/pays';

@Component({
  selector: 'calendar',
  styleUrls: ['./style.css'],
  templateUrl: 'calendar.component.html',
  providers: [EventService, RdvsService, NotificationService],
})
export class CalendrierComponent implements OnInit {
  
  @ViewChild(CalendarComponent) calendar: CalendarComponent;
  changeCalendarView(view) {
    this.calendar.fullCalendar('changeView', view);
  }

  @ViewChild('closeBtnRdvAdd') closeBtnRdvAdd: ElementRef;
  @ViewChild('closeBtnRdvUpdate') closeBtnRdvUpdate: ElementRef;
  @ViewChild('closeBtnRdvDemande') closeBtnRdvDemande: ElementRef;

  medecin: any;
  secretaire: any;
  titre: any;
  form: any;
  start: any;
  events: any[] = [];
  event: any;
  public search: string = '';
  interval: any;
  messages: any[] = [];
  today: any;
  rdvsToday: any[];
  searchDate: any;
  day: any;
  month: any;
  year: any;
  showErrorDate = false;
  medecinId: any;
  eventClicked: any;
  updateform: any;
  hours = [];
  optionsTimeFermeture = [];
  deleteAndProposeForm: any;
  allData = [];
  showTitle = true;

  p: number = 1;

  showAndHideDelete = false;

  calendarOptions: any = {
    header: {
      left: 'prev,next today',
      center: 'title',
      right: 'month,agendaWeek,agendaDay',
    },
    locale: 'fr',
    lang: 'fr',
    buttonText: {
      today: "Aujourd'hui",
      month: 'Mois',
      week: 'Semaine',
      day: 'Jour',
    },
    allDayDefault: false,
    selectable: true,
    selectHelper: true,
    editable: true,
    height: 1200,
    timeFormat: 'H:mm',
    eventLimit: true, // allow "more" link when too many events
    events: []
  };

  refresh = new Subject<void>();
  
  constructor(
    private rdvService: RdvsService,
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private eventService: EventService,
    private http: HttpClient,
    private modalService: NgbModal,
    private toastService: ToastService,
  ) {
    this.hours = times;
  }

  ngOnInit(): void {
    this.getMedecinId();
    this.initializeMedecin();
    this.initializeDate();
    this.initializeForms();
    this.loadRdvs();
    this.loadEvents();
  }

  getMedecinId() {
    if (localStorage.getItem('medecin')) {
      var medecinString = localStorage.getItem('medecin');
      var medecinId = JSON.parse(medecinString).id;
      this.medecinId = medecinId;
    } else {
      return;
    }
  }

  initializeMedecin(): void {
    if (this.medecinId) {
      this.authService.getProfileMedecin().subscribe(
        (profile) => {
          this.medecin = profile;
          if (
            !this.medecin &&
            !this.medecin.roles &&
            !this.medecin.roles.gestionAgenda
          ) {
            this.router.navigate(['medecin/not-found']);
            return false;
          }
        },
        (err) => {
          console.log(err);
          return false;
        }
      );
    }
  }

  initializeDate(): void {
    const currentDate = new Date();
    this.day = currentDate.getDate();
    this.month = currentDate.getMonth() + 1;
    this.year = currentDate.getFullYear();
    if (this.day < 10) {
      this.day = '0' + this.day;
    }
    if (this.month < 10) {
      this.month = '0' + this.month;
    }
    this.today = this.day + '/' + this.month + '/' + this.year;
    this.searchDate = this.year + '-' + this.month + '-' + this.day;
  }

  initializeForms(): void {
    this.form = this.formBuilder.group({
      type: ['Personnel', [Validators.required]],
      title: ['', [Validators.minLength(3), Validators.maxLength(25)]],
      timeStart: ['', Validators.required],
      timing: ['15', [Validators.required]],
      timeEnd: ['', [Validators.required]],
    });

    this.updateform = this.formBuilder.group({
      type: ['', [Validators.required]],
      title: ['', [Validators.minLength(3), Validators.maxLength(25)]],
      start: ['', Validators.required],
      end: ['', Validators.required],
    });

    this.deleteAndProposeForm = this.formBuilder.group({
      jour: ['', Validators.required],
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
  }

  loadRdvs(): void {
    this.rdvService.getAllRdvsByDate(this.searchDate, this.medecinId).subscribe(
      (rdvs) => {
        this.rdvsToday = rdvs;
      },
      (err) => {
        console.log(err);
        return false;
      }
    );
  }

  loadEvents(): void {
    this.eventService.getAllEventsByMedecin(this.medecinId).subscribe(
      (events: any[]) => {
        this.events = events;
        this.calendarOptions.events = this.events;
        $('#calendar').fullCalendar(
          'renderEvents',
          this.calendarOptions.events,
          true
        );
      },
      (err) => {
        return false;
      }
    );
  }

  eventClick(calEvent) {
    this.showAndHideDelete = false;
    this.showErrorDate = false;
    this.eventClicked = {
      _id: calEvent._id,
      title: calEvent.title,
      identifiant: calEvent.identifiant,
      motif: calEvent.motif,
      type: calEvent.type,
      backgroundColor: calEvent.backgroundColor,
      start: calEvent.start._i,
      end: calEvent.end._i,
      nom_patient: calEvent.nom_patient,
      prenom_patient: calEvent.prenom_patient,
      email: calEvent.email,
      tel: calEvent.tel,
      patientId: calEvent.patientId,
    };
    if (
      calEvent.backgroundColor == '#039be5' ||
      calEvent.backgroundColor == '#23b1a5'
    ) {
      jQuery('#detailsEventModal').modal('show');
    }
    if (calEvent.backgroundColor == '#F48A54') {
      jQuery('#detailsEventModalDemandeRDV').modal('show');
    }
  }
  dayClick(details) {
    var todayData = new Date().toISOString().split('T')[0];
    this.form.get('type').setValue('Personnel');
    this.form.get('timing').setValue('15');
    this.start = details.date.format();
    this.showTitle = true;

    if (todayData > this.start) {
      return false;
    }
    jQuery('#createEventModal').modal('show');
  }

  addEvent(event: CalendarEvent): void {
    this.events = [...this.events, event];
    this.refresh.next();
  }

  saveEvent() {
    let identifiant =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    this.allData = [];
    this.form.value.identifiant = identifiant;
    this.form.value.medecinId = this.medecinId;

    if (!Array.isArray(this.calendarOptions.events)) {
      this.calendarOptions.events = [];
    }

    if (this.form.value.type == 'Personnel') {
      this.form.value.backgroundColor = '#039be5';
      var rdvObject = {
        identifiant: this.form.value.identifiant,
        start: this.start + 'T' + this.form.value.timeStart,
        end: this.start + 'T' + this.form.value.timeEnd,
        type: this.form.value.type,
        backgroundColor: this.form.value.backgroundColor,
        medecinId: this.form.value.medecinId,
        title: this.form.value.title,
      };
      this.eventService.registerEvent(rdvObject).subscribe((event: any) => {
        this.calendarOptions.events.push(event);
        $('#calendar').fullCalendar('renderEvents', event, true);
        this.showErrorDate = false;
        this.form.reset();
        jQuery('#createEventModal').modal('hide');
      });
      //this.closeBtnRdvAdd.nativeElement.click();
    }
    if (this.form.value.type == 'Professionnel') {
      this.form.value.backgroundColor = '#23b1a5';
      var i = 1;
      var newOject = {
        dateDebut: moment(this.form.value.timeStart, 'HH:mm')
          .add(Number(this.form.value.timing) * Number(i - 1), 'minutes')
          .format('HH:mm'),
        dateFin: moment(this.form.value.timeStart, 'HH:mm')
          .add(Number(this.form.value.timing) * i, 'minutes')
          .format('HH:mm'),
      };
      this.form.value.start = this.start + 'T' + newOject.dateDebut;
      this.form.value.end = this.start + 'T' + newOject.dateFin;
      let hasExistEvent = this.calendarOptions.events.some(
        (event) => event['start'] === this.form.value.start
      );
      if (!hasExistEvent) {
        this.allData.push(this.form.value);
      }
      var endTime = moment(this.form.value.timeEnd, 'HH:mm');
      while (moment(newOject.dateFin, 'HH:mm').isBefore(endTime)) {
        i++;
        var newOject = {
          dateDebut: moment(this.form.value.timeStart, 'HH:mm')
            .add(Number(this.form.value.timing) * Number(i - 1), 'minutes')
            .format('HH:mm'),
          dateFin: moment(this.form.value.timeStart, 'HH:mm')
            .add(Number(this.form.value.timing) * i, 'minutes')
            .format('HH:mm'),
        };
        var rdvObject = {
          identifiant: this.form.value.identifiant,
          start: this.start + 'T' + newOject.dateDebut,
          end: this.start + 'T' + newOject.dateFin,
          type: this.form.value.type,
          backgroundColor: this.form.value.backgroundColor,
          medecinId: this.form.value.medecinId,
          title: this.form.value.title,
        };

        let hasExistEvent = this.calendarOptions.events.some(
          (event) => event['start'] === rdvObject.start
        );
        if (!hasExistEvent) {
          this.allData.push(rdvObject);
        }
      }
      if (this.allData.length > 0) {
        this.eventService
          .registerEvent(this.allData)
          .subscribe((event: any[]) => {
            if (event) {
              for (var i = 0; i < event.length; i++) {
                this.calendarOptions.events.push(event[i]);
              }
              this.toastService.showSuccess("Evenement ajouté avec succès!")
            }
            $('#calendar').fullCalendar('renderEvents', event, true);
            this.showErrorDate = false;
            this.form.reset();
          });
        //this.closeBtnRdvAdd.nativeElement.click();
      }
      if (this.allData.length < 1) {
        alert('Vérifier les horaires choisis');
        return false;
      }
    }
  }

  deleteEvent(event) {
    var eventId = event._id;
    if (window.confirm('voulez vous vraiment supprimer cet événement?')) {
      var headers = new HttpHeaders();
      headers.append('Authorization', localStorage.getItem('id_token'));
      this.http
        .delete(_getURL(API_CONFIG.event) + '/' + eventId, { headers: headers })
        .subscribe((res: any) => {
          this.calendarOptions.events.splice(
            this.calendarOptions.events.indexOf(event),
            1
          );
          $('#calendar').fullCalendar('removeEvents', event._id);
        });
      //this.closeBtnRdvUpdate.nativeElement.click();
    }
  }

  deleteEventSerie(eventClicked) {
    if (window.confirm('voulez vous vraiment supprimer ces événements?')) {
      for (var j = 0; j < this.calendarOptions.events.length; j++) {
        if (
          this.calendarOptions.events[j].identifiant == eventClicked.identifiant
        ) {
          //this.calendarOptions.events.splice(this.calendarOptions.events.indexOf(this.calendarOptions.events[j]),1)
          $('#calendar').fullCalendar(
            'removeEvents',
            this.calendarOptions.events[j]._id
          );
        }
      }
      var headers = new HttpHeaders();
      headers.append('Authorization', localStorage.getItem('id_token'));
      this.http
        .delete(
          _getURL(API_CONFIG.event) +
            '/byIdentifiant/' +
            eventClicked.identifiant,
          { headers: headers }
        )
        .subscribe((res: any) => {});
      //this.closeBtnRdvUpdate.nativeElement.click();
    }
  }

  modifierEvent(event) {
    if (event.start >= event.end) {
      this.showErrorDate = true;
      return false;
    }

    if (event.type == 'Personnel') {
      event.backgroundColor = '#039be5';
    }
    if (event.type == 'Professionnel') {
      event.backgroundColor = '#23b1a5';
    }
    $('#calendar').fullCalendar('removeEvents', event._id);
    $('#calendar').fullCalendar('renderEvent', event, true);
    this.eventService.editEvent(event).subscribe((_event) => {
      this.event = _event;
    });
    //this.closeBtnRdvUpdate.nativeElement.click();
  }

  updateDuration(duration: number): void {
    this.form.get('timing').setValue(duration);
  
    document.querySelectorAll('.event-duration').forEach((button) => {
      button.classList.remove('active');
    });
  
    const activeButton = document.querySelector(`button[data-duration="${duration}"]`);
    if (activeButton) {
      activeButton.classList.add('active');
    }
  
    this.controlerTimeFermeture();
  }

  controlerTimeFermeture() {
    this.optionsTimeFermeture = [];
    var numberDuration = Number(this.form.value.timing);
    for (var i = 1; i < this.hours.length; i++) {
      var date = moment(this.form.value.timeStart, 'HH:mm')
        .add(numberDuration * i, 'minutes')
        .format('HH:mm');
      if (this.hours.indexOf(date) >= this.hours.indexOf('23:45')) {
        return false;
      }
      if (this.optionsTimeFermeture.indexOf(date) === -1) {
        this.optionsTimeFermeture.push(date);
      }
    }
  }

  showAndHideTitle() {
    if (this.form.value.type == 'Personnel') {
      this.showTitle = true;
      return true;
    }
    if (this.form.value.type == 'Professionnel') {
      this.showTitle = false;
      return false;
    }
  }

  AcceptedDemande(eventClicked) {
    if (window.confirm('Voulez vous vraiment accepter cette demande ?')) {
      eventClicked.backgroundColor = '#008000';
      $('#calendar').fullCalendar('removeEvents', eventClicked._id);
      $('#calendar').fullCalendar('renderEvent', eventClicked, true);
      var notification = {
        nom_medecin: this.medecin.nom,
        prenom_medecin: this.medecin.prenom,
        accepted: true,
        texte: 'a accepté votre demande de rendez vous',
        lien: 'agenda',
        patientId: eventClicked.patientId,
      };

      var rdv = {
        nom_patient: eventClicked.nom_patient,
        prenom_patient: eventClicked.prenom_patient,
        prenom_medecin: this.medecin.prenom,
        email: eventClicked.email,
        tel: eventClicked.tel,
        date: eventClicked.start,
        motif: eventClicked.motif,
        medecinId: this.medecin._id,
        emailMedecin: this.medecin.email,
        eventId: eventClicked._id,
      };

      var rdvPatient = {
        nom_patient: eventClicked.nom_patient,
        prenom_patient: eventClicked.prenom_patient,
        prenom_medecin: this.medecin.prenom,
        nom_medecin: this.medecin.nom,
        email: eventClicked.email,
        tel: eventClicked.tel,
        date: eventClicked.start,
        motif: eventClicked.motif,
        patientId: eventClicked.patientId,
        emailMedecin: this.medecin.email,
        adresseCabinet: this.medecin.adresseCabinet,
        telMedecin: this.medecin.telFixe,
        backgroundColor: '#008000',
        eventId: eventClicked._id,
      };

      this.notificationService
        .addNotification(notification)
        .subscribe((notification) => {});
      this.rdvService.saveRDVeVENT(rdv).subscribe((rdv) => {});
      this.rdvService.saveRDVeVENT(rdvPatient).subscribe((rdv) => {});
      this.eventService.editEvent(eventClicked).subscribe((_event) => {});
      //this.closeBtnRdvDemande.nativeElement.click();
    }
  }

  refuserDemande(eventClicked) {
    if (
      window.confirm(
        'Voulez vous vraiment refuser complétement cette demande ?'
      )
    ) {
      var notification = {
        nom_medecin: this.medecin.nom,
        prenom_medecin: this.medecin.prenom,
        accepted: false,
        texte: 'a refusé votre demande de rendez vous',
        lien: 'agenda',
        patientId: eventClicked.patientId,
      };
      this.notificationService
        .addNotification(notification)
        .subscribe((notification) => {});
      var headers = new HttpHeaders();
      headers.append('Authorization', localStorage.getItem('id_token'));
      this.http
        .delete(_getURL(API_CONFIG.event) + '/' + eventClicked._id, {
          headers: headers,
        })
        .subscribe((res: any) => {
          this.calendarOptions.events.splice(
            this.calendarOptions.events.indexOf(eventClicked),
            1
          );
          $('#calendar').fullCalendar('removeEvents', eventClicked._id);
        });
      //this.closeBtnRdvDemande.nativeElement.click();
    }
  }

  hideDelete() {
    this.showAndHideDelete = true;
  }

  proposerOtherDate(eventClicked) {
    var nowDate = moment().format('YYYY-MM-DDTHH:mm');
    var start =
      this.deleteAndProposeForm.value.jour +
      'T' +
      this.deleteAndProposeForm.value.start;
    var end =
      this.deleteAndProposeForm.value.jour +
      'T' +
      this.deleteAndProposeForm.value.end;

    if (start <= nowDate || start >= end) {
      this.showErrorDate = true;
      return false;
    }
    var notification = {
      nom_medecin: this.medecin.nom,
      prenom_medecin: this.medecin.prenom,
      accepted: false,
      texte:
        'a refusé votre demande de rendez vous.Il vous a proposé un nouveau rendez vous.Merci de vérifier votre mail',
      lien: 'agenda',
      patientId: eventClicked.patientId,
    };

    var rdvObject = {
      start: start,
      end: end,
      type: 'Professionnel',
      backgroundColor: '#23b1a5',
      medecinId: this.medecin._id,
      title: 'Prop',
      nom_patient: eventClicked.nom_patient,
      prenom_patient: eventClicked.prenom_patient,
      nom_medecin: this.medecin.nom,
      prenom_medecin: this.medecin.prenom,
      email: eventClicked.email,
      motif: eventClicked.motif,
      tel: eventClicked.tel,
    };

    this.notificationService
      .addNotification(notification)
      .subscribe((notification) => {});
    this.eventService
      .registerEventProposition(rdvObject)
      .subscribe((event: any) => {
        this.calendarOptions.events.push(event);
        $('#calendar').fullCalendar('renderEvents', event, true);
        this.deleteAndProposeForm.reset();
      });
    var headers = new HttpHeaders();
    headers.append('Authorization', localStorage.getItem('id_token'));
    this.http
      .delete(_getURL(API_CONFIG.event) + '/' + eventClicked._id, {
        headers: headers,
      })
      .subscribe((res: any) => {
        this.calendarOptions.events.splice(
          this.calendarOptions.events.indexOf(eventClicked),
          1
        );
        $('#calendar').fullCalendar('removeEvents', eventClicked._id);
      });
    //this.closeBtnRdvDemande.nativeElement.click();
  }

  onReset() {
    jQuery('#createEventModal').modal('hide');
  }

}
