<app-header></app-header>

<div class="container-fluid p-sm-5 bg-light" style="height: 99%">
  <div class="row">
    <div class="col-lg-12">
      <ul class="breadcrumb float-md-right">
        <li class="breadcrumb-item">
          <a class="color-blue" routerLink="/"><i class="fa-solid fa-house"></i> Accueil</a>
        </li>
        <li class="breadcrumb-item active">Rendez-vous</li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-5 col-md-6 col-sm-12">
      <h4>Liste des Rendez-vous</h4>
    </div>
  </div>

  <div class="col-md-12">
    <div class="row justify-content-end">
      <div class="col-auto">
        <button (click)="addRdvModal()" class="btn btn-primary">
          Ajouter un Rendez-vous
        </button>
      </div>
      <!-- <div class="col-auto">
                <button class="btn btn-primary">Exporter</button>
            </div> -->
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-md-12 mb-3 mb-md-0">
      <div class="form-group">
        <div class="col row">
          <input type="text" class="col form-control mr-2" placeholder="Id Patient" [(ngModel)]="idPatientToFiltred" (input)="filter()" />
          <input type="text" class="col form-control mr-2" placeholder="N° Fiche" [(ngModel)]="numFicheToFiltred" (input)="filter()" onkeypress="return /[0-9]/.test(event.key)" />
          <input type="text" class="col form-control mr-2" placeholder="Nom et Prénom" [(ngModel)]="nomPrenomToFiltred" (input)="filter()" />
          <input type="date" class="col form-control mr-2" placeholder="Date RDV" [(ngModel)]="dateToFiltred" (change)="filter()" />
          <select class="col form-control mr-2" [(ngModel)]="statusToFiltred" (change)="filter()">
            <option value="">Status</option>
            <option value="Disponible">Disponible</option>
            <option value="Annulé">Annulé</option>
            <option value="En attente de confirmation">En attente de confirmation</option>
            <option value="Confirmé">Confirmé</option>
            <option value="Reporté">Reporté</option>
          </select>
          <button (click)="resetFilters()" class="btn btn-primary p-2 mr-1">
            <i class="fa-solid fa-arrows-rotate"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr class="ligne-paire text-center">
              <th scope="col" style="color: #589590"></th>
              <th scope="col" style="color: #589590">Id de Patient</th>
              <th scope="col" style="color: #589590">N° de fiche</th>
              <th scope="col" style="color: #589590">Nom et Prénom</th>
              <th scope="col" style="color: #589590">Date</th>
              <th scope="col" style="color: #589590">Temps</th>
              <th scope="col" style="color: #589590">Statut</th>
              <th scope="col" style="color: #589590">Actions</th>
            </tr>
          </thead>
          <tbody *ngFor="
              let i of rdvs
                | paginate
                  : {
                      itemsPerPage: tableSize,
                      currentPage: page,
                      totalItems: count
                    };
              let k = index
            ">
            <tr [class.ligne-paire]="k % 2 !== 0" [class.ligne-impaire]="k % 2 === 0" class="text-center">
              <td>
                <div class="rounded-circle overflow-hidden mx-auto" style="width: 30px; height: 30px">
                  <img src="../../assets/user.png" alt="Image" class="img-fluid" />
                </div>
              </td>
              <td>{{ i.idPatient }}</td>
              <td>{{ i.numFiche }}</td>
              <td>{{ i.nom_patient }} {{ i.prenom_patient }}</td>
              <td>
                <i class="icon fa-solid fa-calendar-days mr-2"></i>{{ i.date | date : "dd/MM/yyyy" }}
              </td>
              <td>
                <i class="icon fa-solid fa-clock mr-2"></i>{{ i.startTime }}
              </td>
              <td>
                <span class="badge badge-primary" *ngIf="i.status === 'Disponible'">{{ i.status }}</span>
                <span class="badge badge-danger" *ngIf="i.status === 'Annulé'">{{ i.status }}</span>
                <span class="badge badge-waiting-conf" *ngIf="i.status === 'En attente de confirmation'">{{ i.status }}</span>
                <span class="badge badge-custom-success" *ngIf="i.status === 'Confirmé'">{{ i.status }}</span>
                <span class="badge badge-warning" *ngIf="i.status === 'Reporté'">{{ i.status }}</span>
              </td>
              <td *ngIf="i.status === 'En attente de confirmation'">
                <div class="actions">
                  <!-- <a (click)="consulterRdv(i._id)"
                    ><i
                      class="fa-solid fa-eye mr-2"
                      title="Consulter un rendez-vous"
                    ></i
                  ></a> -->
                  <a (click)="modalConfirmRdv(i._id)"><i class="fa-solid fa-calendar-check mr-2" title="Confirmer ce rendez-vous"></i></a>
                  <a (click)="modalAnnulerRdv(i._id)"><i class="fa-solid fa-calendar-xmark cancel-icon" title="Rejeter ce rendez-vous"></i></a>
                </div>
              </td>
              <td *ngIf="i.status !== 'En attente de confirmation'">
                <div class="actions">
                  <!-- <a (click)="consulterRdv(i._id)"
                    ><i
                      class="fa-solid fa-eye mr-2"
                      title="Consulter un rendez-vous"
                    ></i
                  ></a> -->
                  <a (click)="updateRdv(i._id)"><i class="fa-solid fa-pen-to-square mr-2" title="Modifier ce rendez-vous"></i></a>
                  <a (click)="modalAnnulerRdv(i._id)"><i class="fa-solid fa-calendar-xmark cancel-icon" title="Annuler ce rendez-vous"></i></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="rdvs && rdvs.length > 10" class="border-0 mt-4">
          <div class="d-flex justify-content-center">
            <pagination-controls class="my-pagination" previousLabel="Précédent" nextLabel="Suivant" (pageChange)="onTableDataChange($event)">
            </pagination-controls>
          </div>
        </div>
        <div *ngIf="rdvs && rdvs.length == 0" class="table-responsive" style="text-align: center">
          <p style="padding-bottom: 9rem; padding-top: 9rem">
            Aucun Rendez-vous n'est trouvé
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>