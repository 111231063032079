import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { OrderByPipe } from '../pipes/orderByPipe/order-by.pipe';
import { FullscreenService } from '../services/fullscreen/fullscreen.service';
import { AuthService } from '../services/auth/auth.service';
import { RdvsService } from '../services/rdvs/rdvs.service';

@Component({
  selector: 'app-salle-att',
  templateUrl: './salle-att.component.html',
  styleUrls: ['./salle-att.component.css'],
  providers: [OrderByPipe]
})
export class SalleAttComponent implements OnInit {

  currentDate: string;
  currentDayOfWeek: string;
  currentDay: string;
  currentMonth: string;
  currentYear: string;
  currentTime: string;

  currentDateFormatted: string;

  page: number = 1;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  count: number = 0;

  medecinId: string | null;
  medecin: any;
  rdvs: any[] = [];

  runText: string;
  text1: string;
  text2: string;

  constructor(
    private orderByPipe: OrderByPipe,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private el: ElementRef,
    public fullscreenService: FullscreenService,
    private authService: AuthService,
    private rdvService: RdvsService
  ) {
      this.text1 = "Bienvenue!";
      this.text2 = "Welcome!"
   }

  ngOnInit(): void {
    this.getMedecinId();
    this.getConnectedMedecin();
    this.getRdvsToday();
    this.updateCurrentDate();
    this.attachFullscreenListener();

    setInterval(() => {
      this.updateCurrentDate();
      this.updateRemainingTime();
      this.updateRdvs();
    }, 1000);
  }

  getMedecinId() {
    if (localStorage.getItem("medecin")) {
      var medecinString = localStorage.getItem('medecin');
      var medecinId = JSON.parse(medecinString).id;
      this.medecinId = medecinId;
    } else {
      return
    }
  }

  getConnectedMedecin(){
    this.authService.getProfileMedecin().subscribe(
      (data: any) => {
        this.medecin = data.medecin;
        this.runText = `<i class="fa-solid fa-user-doctor fa-xs mr-1"></i> Bienvenue au cabinet Dr. ${this.medecin.prenom} ${this.medecin.nom}`;
        this.runText += `${this.addSpaces(13)} <i class="fa-solid fa-map-location-dot fa-xs mr-1"></i> ${this.medecin.adresseCabinet.adresse}`;
        this.runText += `${this.addSpaces(13)} <i class="fa-solid fa-phone fa-xs mr-1"></i> +216 ${this.medecin.tel}`;
        this.runText += `${this.addSpaces(13)} <i class="fa-solid fa-envelope fa-xs mr-1"></i> ${this.medecin.email}`;
      }
    );
  }

  getCurrentDateInFormat(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  getRdvsToday() {
    this.currentDateFormatted = this.getCurrentDateInFormat();
  
    this.rdvService.getRdvsToday(this.currentDateFormatted, this.medecinId).subscribe(
      (rdvs: any[]) => {
        this.rdvs = rdvs.map(rdv => {
          if (rdv.start) {
            const timePart = rdv.start.split('T')[1];
            const [hours, minutes] = timePart.split(':');
            rdv.start = `${this.padZero(Number(hours))}:${this.padZero(Number(minutes))}`;
          }
          return rdv;
        });
        this.updateRemainingTime();
      },
      (error) => {
        console.error('Error fetching RDVs:', error);
      }
    );
  }

  private attachFullscreenListener() {
    document.addEventListener('fullscreenchange', () => {
      this.handleFullscreenChange();
    });
  }

  private handleFullscreenChange() {
    const isFullscreen = !!document.fullscreenElement;
    const header = this.el.nativeElement.querySelector('.class-to-hide-on-full-screen');

    if (header) {
      if (isFullscreen) {
        this.renderer.setStyle(header, 'display', 'none');
      } else {
        this.renderer.setStyle(header, 'display', 'flex');
      }
    }
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  formatCurrentDate(date: Date) {
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };

    const locale = 'fr-FR';
    const formattedDate = new Intl.DateTimeFormat(locale, options).format(date);
    const parts = formattedDate.split(' ');
    const capitalizedParts = parts.map((part) =>
      this.capitalizeFirstLetter(part)
    );
    const finalDate = capitalizedParts.join(' ').replace('À', '-');

    return finalDate;
  }

  updateCurrentDate() {
    const currentDateObj = new Date();
    this.currentDate = this.formatCurrentDate(currentDateObj);

    const [datePart, timePart] = this.currentDate.split(" - ");
    const dateComponents = datePart. split (" ");

    this.currentDayOfWeek = dateComponents[0];
    this.currentDay = dateComponents[1];
    this.currentMonth = dateComponents[2];
    this.currentYear = dateComponents[3];

    this.currentTime = timePart.substring(0, 5);
  }

  updateRemainingTime() {
    const currentDate = new Date();
    const currentTime = currentDate.getTime();
  
    this.rdvs.forEach(rdv => {
      if (rdv.start) {
        const rdvDateTimeString = `${currentDate.toISOString().split('T')[0]}T${rdv.start}:00`;
        const rdvTime = new Date(rdvDateTimeString);
  
        if (isNaN(rdvTime.getTime())) {
          rdv.resTime = '00:00';
          return;
        }
  
        const timeDifference = rdvTime.getTime() - currentTime;
  
        if (timeDifference < 0) {
          rdv.resTime = '00:00';
          return;
        }
  
        const hoursRemaining = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutesRemaining = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  
        const adjustedMinutesRemaining = minutesRemaining + 1;
        const adjustedHoursRemaining = hoursRemaining + Math.floor(adjustedMinutesRemaining / 60);
        const finalMinutesRemaining = adjustedMinutesRemaining % 60;
  
        rdv.resTime = `${adjustedHoursRemaining.toString().padStart(2, '0')}:${finalMinutesRemaining.toString().padStart(2, '0')}`;
      } else {
        rdv.resTime = '00:00';
      }
    });
  }  

  isCriticalTime(resTime: string | undefined): boolean {
    if (resTime) {
      const [hours, minutes] = resTime.split(':').map(Number);
      if (!isNaN(hours) && !isNaN(minutes)) {
        const totalMinutesRemaining = hours * 60 + minutes;
        return totalMinutesRemaining <= 5 && totalMinutesRemaining > 0;
      }
    }
    return false; // Default to false if resTime is undefined or invalid
  }
  
  isDoneTime(resTime: string | undefined): boolean {
    if (resTime) {
      const [hours, minutes] = resTime.split(':').map(Number);
      if (!isNaN(hours) && !isNaN(minutes)) {
        const totalMinutesRemaining = hours * 60 + minutes;
        return totalMinutesRemaining === 0;
      }
    }
    return false; // Default to false if resTime is undefined or invalid
  }

  updateRdvs() {
    const sortedRdvs = this.orderByPipe.transform(this.rdvs, 'start');
    this.rdvs = sortedRdvs;
    this.cdr.detectChanges();
  }

  padZero(num: number) {
    return num < 10 ? `0${num}` : `${num}`;
  }

  addSpaces(count: number): string {
    return '&nbsp;'.repeat(count);
  }

  onTableDataChange(event: any) {
    this.page = event;
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
  }

}
