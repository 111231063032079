<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="/medecin/accueil"> <img src="../assets/output-onlinepngtools_81.png" alt="Logo" class="img-fluid" style="max-width: 70%;"></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mx-auto text-center">
      <li class="nav-item">
        <a class="nav-link" routerLink="/medecin/accueil">Accueil</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/medecin/patients-liste">Patient</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/medecin/agenda">Agenda</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/medecin/rendez-vous">Rendez-vous</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/medecin/medecins">Médecins</a>
      </li>
    </ul>
    <!-- <ul class="navbar-nav text-center">
      <li class="nav-item">
        <a class="nav-link" href="/medecin/profile"><i class="fa-solid fa-user"></i> Dr {{ medecin?.prenom }} {{ medecin?.nom }}</a>
      </li>
    </ul> -->
    <div class="dropdown navbar-nav text-center">
      <div class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fa-solid fa-user"></i> Dr {{ medecin?.prenom }} {{ medecin?.nom }}
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="/medecin/profile"><i class="fa-solid fa-user"></i> Profile</a>
          <a class="dropdown-item" href="#" (click)="logout()"><i class="fa-solid fa-right-from-bracket"></i> Se déconnecter</a>
        </div>
      </div>
    </div>
  </div>
</nav>