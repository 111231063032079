import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _getURL, API_CONFIG } from 'config/API_CONFIG';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private apiUrl = _getURL(API_CONFIG.notification)

  constructor(
    private http: HttpClient
  ) { }

  addNotification(notificationData: any): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': localStorage.getItem('id_token'),
      'Content-Type': 'application/json'
    });
    return this.http.post(this.apiUrl, notificationData, { headers: headers })
  }
}
