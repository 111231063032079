import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import countries from 'src/app/services/constants/List_countries.json';
import postCode from 'src/app/services/constants/TN_Postal_codes.json';
import { PatientService } from 'src/app/services/patient/patient.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { Router } from '@angular/router';
import { apciMaxElements, champMaxLength, champMinLength, champsObligatoire, invalidEmailAddress, invalidNumTelephone } from 'src/app/services/constants/messages';
import { ConsultationService } from 'src/app/services/consultation/consultation.service';
import { AuthService } from 'src/app/services/auth/auth.service';


@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.css']
})
export class AddPatientComponent implements OnInit {
  defaultPays = "Tunisie"
  registerForm: FormGroup;
  submitted = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  Sexes = ['Homme', 'Femme']
  statusFamiliales = ['Célibataire', 'Marié(e)', 'Divorcé(e)', 'Veuf(ve)']
  groupeSanguins = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-']
  list_contries = countries
  postal_codes = postCode
  defaultCity = "Tunisie"

  apcis = [
    'Affections coronariennes et leurs complications', 'Affections hypophysaires', 'Affections surrénaliennes', 'Cardiopathies congénitales et valvulopathies',
    'Cirrhoses et insuffisance hépatique', 'Diabète insulinodépendant ou non insulinodépendant', 'Dysthyroïdies', 'Epilepsie', 'Glaucome', 'HTA sévère', 'Hépatites chroniques actives',
    'Insuffisance cardiaque et troubles du rythme', 'Insuffisance respiratoire chronique', 'Insuffisance rénale chronique', 'Les maladies inflammatoires chroniques de l\'intestin',
    'Maladie de Parkinson', 'Maladies auto-immunes', 'Mucoviscidose', 'Phlébites', 'Psychoses et névroses', 'Rhumatismes inflammatoires chroniques', 'Sclérose en plaques',
    'Tuberculose active', 'Tumeurs et hémopathies malignes'
  ]
  typeOrgs = ['CNAM', 'CNSS']
  governorates: any[];
  delegs: any[];
  cites: any
  codePostalChoise: any = ""
  today = new Date()
  medecinId: any;
  // message d'erreur
  champsObligatoire = champsObligatoire
  champMinLength = champMinLength
  champMaxLength = champMaxLength
  invalidEmailAddress = invalidEmailAddress
  invalidNumTelephone = invalidNumTelephone
  apciMaxElements = apciMaxElements
  // end message d'erreur
  codePostalList: any = []
  age: any
  medecin: any
  medecinUsedId: any
  patients: any = []
  constructor(
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    public iziToast: Ng2IzitoastService,
    private router: Router,
    private consultationService: ConsultationService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.governorates = [...new Set(this.postal_codes.map(item => item.Gov))];
    this.registerForm = this.formBuilder.group({
      numFiche: ['', [Validators.required, Validators.maxLength(25),]],
      nom: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(3)]],
      prenom: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(3)]],
      dateAnniversaire: ['', Validators.required],
      email: ['', Validators.email],
      tel: ['', Validators.required],
      profession: ['',],
      groupeSanguin: ['',],
      sexe: ['', Validators.required],
      statusFamilial: ['',],
      ville: ['', [Validators.minLength(3), Validators.maxLength(25)]],
      pays: [''],
      codePostal: ['', [Validators.maxLength(4)]],
      adresse: this.formBuilder.group({
        adresse: ['', [Validators.minLength(3), Validators.maxLength(500)]],
        ville: ['', [Validators.minLength(3), Validators.maxLength(25)]],
        pays: [''],
        codePostal: ['', [Validators.maxLength(4)]],
      }),
      apci: [[],],
      orgSante: this.formBuilder.group({
        typeOrg: [''],
        nomEtablissement: ['', [Validators.maxLength(25)]],
        email: ['',],
        tel: ['+216'],
        adresse: this.formBuilder.group({
          ville: ['', [Validators.maxLength(25)]],
          codePostal: ['', [Validators.maxLength(4)]],
          adresse: ['', Validators.maxLength(500)],
        }),
      }),
    },);
    // this.registerForm.value.pays = "Tunisie"
    let listdelegs = this.postal_codes.filter(x => x.Gov === "Tunis")
    const delegsNames = listdelegs.map(deleg => deleg.Deleg);
    this.delegs = [...new Set(delegsNames)];
    if (localStorage.getItem('medecin')) {
      this.medecinId = JSON.parse(localStorage.getItem('medecin')).id
    } else {
      return
    }
    this.authService.getProfileMedecin().subscribe((profile: any) => {
      this.medecin = profile.medecin;
      var medecinId = this.medecin._id;
      this.medecinUsedId = medecinId
      this.getPatients()
      if (!this.medecin) {
        this.router.navigate(['/medecin/accueil']);
        return false;
      }
    },
      err => {
        this.router.navigate(['/medecin/accueil']);
        return false;
      });
  }
  get f() { return this.registerForm.controls; }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    if (this.registerForm.value.tel) {
      this.registerForm.value.tel = this.registerForm.value.tel.internationalNumber
    }
    var birthDate = new Date(this.registerForm.value.dateAnniversaire);
    this.age = this.today.getFullYear() - birthDate.getFullYear();
    var m = this.today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && this.today.getDate() < birthDate.getDate())) {
      this.age--;
    }
    this.registerForm.value.listMedecinsId = this.medecinId
    this.registerForm.value.createdByDoctor = true;
    this.registerForm.value.age = this.age;
    this.registerForm.value.expiredAt = null
    //!------ idPatient section
    // let lastFourNumInscription = this.medecin.numInscription.slice(-4);
    // let currentYear = this.today.getFullYear();
    // let newIdNumber = 1;  // Default to 1 if there are no patients or it's a new year

    // if (this.patients && this.patients.length > 0) {
    //   let lastPatientId = this.patients[this.patients.length - 1].idPatient;
    //   let parts = lastPatientId.split('-');
    //   let lastYear = parseInt(parts[2]);

    //   if (lastYear === currentYear) {
    //     let lastIdNumber = parseInt(parts[3]);
    //     newIdNumber = lastIdNumber + 1;
    //   }
    // }

    // this.registerForm.value.idPatient = "Mediv-M" + lastFourNumInscription + "-" + currentYear + "-" + newIdNumber;
    //!------ idPatient section
    this.consultationService.registerPatient(this.registerForm.value).subscribe(
      (patient: any) => {
        if (patient && patient._id) {
          this.iziToast.show({
            message: 'Bien ajouté',
            messageColor: '#386641',
            progressBarColor: '#6a994e',
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#dde5b6',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay: false,
          });
          this.router.navigate(['/medecin/consultation-liste', patient._id]);
          this.resetForm()


          return true;
        }
        if ((!patient) || (patient && !patient._id)) {
          setTimeout(() => {
            this.router.navigate(['/medecin/patients-liste']);
          }, 2000);
          this.resetForm()
          return true;
        }
      }
    );
  }
  getPatients() {
    this.consultationService.getAllPatientsByMedecin(this.medecinUsedId)
      .subscribe((patients: any[]) => {
        this.patients = patients;
        this.patients = this.patients.filter(el => el.createdByDoctor == true)
      });
  }
  resetForm() {
    this.registerForm.reset();
    this.submitted = false;
  }
  getPays() {
    this.registerForm.get('adresse.ville').patchValue('');
    this.registerForm.get('adresse.codePostal').patchValue('');
    this.registerForm.get('adresse.adresse').patchValue('');
  }
  getVille(cov) {
    this.registerForm.get('adresse.codePostal').patchValue('');
    let listdelegs = this.postal_codes.filter(x => x.Gov === cov)
    // Extraction des noms des délégations
    const delegsNames = listdelegs.map(deleg => deleg.Deleg);
    // Élimination des doublons en utilisant un Set
    this.delegs = [...new Set(delegsNames)];
    this.codePostalList = [...new Set(listdelegs.map(deleg => deleg.zip))]
  }

}
