<app-header></app-header>

<div class="container" *ngIf="medecin">
  <div class="row">
    <div class="col-lg-12">
      <ul class="breadcrumb float-md-right">
        <li class="breadcrumb-item"><a class="color-blue" routerLink="/medecin/accueil"><i class="fa-solid fa-house"></i>
            Accueil</a>
        </li>
        <li class="breadcrumb-item active">Profile</li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <!-- Page title -->
      <div class="my-5">
        <h3>Profil du médecin</h3>
        <hr style="margin: 0; width: 20%; color: #7fd8d2; height: 3px;">
      </div>
      <!-- Form START -->
      <form class="file-upload">
        <div class="row mb-5">
          <!-- Contact detail -->
          <div class="col-lg-8 mb-5">
            <div class="bg-secondary-soft px-4 py-5 rounded">
              <div class="row g-3">
                <div class="col-lg-6">
                  <h4 class="mb-5 mt-0">Détails de contact</h4>
                </div>
                <div class="col-lg-6 text-end">
                  <span [ngClass]="{'span-langue-fr activeLangueFr': versionFr, 'span-langue-fr': !versionFr}" (click)="showVersionFr()">FR</span>
                  <span [ngClass]="{'span-langue-ar activeLangueAr': versionAr, 'span-langue-ar': !versionAr}" (click)="showVersionAr()">AR</span>
                </div>
                <!-- First Name -->
                <div class="col-md-6" *ngIf="versionFr">
                  <label class="form-label">Nom *</label>
                  <input type="text" class="form-control" placeholder="Nom" aria-label="First name" [(ngModel)]="medecin.nom" name="nom" disabled>
                </div>
                <!-- Last name -->
                <div class="col-md-6" *ngIf="versionFr">
                  <label class="form-label">Prénom *</label>
                  <input type="text" class="form-control" placeholder="Prénom" aria-label="Last name" [(ngModel)]="medecin.prenom" name="prenom" disabled>
                </div>
                <!-- Numéro d'inscription -->
                <div class="col-md-6 mt-3" *ngIf="versionFr">
                  <label for="inputNumInscription" class="form-label">N° Inscription *</label>
                  <input type="text" placeholder="N° Inscription" class="form-control" id="inputNumInscription" style="cursor: no-drop;" [(ngModel)]="medecin.numInscription" name="numInscription" disabled>
                </div>
                <!-- Email -->
                <div class="col-md-6 mt-3" *ngIf="versionFr">
                  <label for="inputEmail4" class="form-label">Email *</label>
                  <input type="email" placeholder="Email" class="form-control" id="inputEmail4" [(ngModel)]="medecin.email" name="email">
                </div>
                <!-- Phone number -->
                <div class="col-md-6 mt-3" *ngIf="versionFr">
                  <label class="form-label">N° mobile</label>
                  <international-phone-number [(ngModel)]="medecin.tel" name="tel" placeholder="N° mobile" [maxlength]="18" [defaultCountry]="'tn'" [locale]="'tn'" [required]="true"></international-phone-number>
                </div>
                <!-- Mobile number -->
                <div class="col-md-6 mt-3" *ngIf="versionFr">
                  <label class="form-label">N° fixe</label>
                  <international-phone-number [(ngModel)]="medecin.telFixe" name="telFixe" placeholder="N° fixe" [maxlength]="18" [defaultCountry]="'tn'" [locale]="'tn'" [required]="true"></international-phone-number>
                </div>
                <!-- Code couleur -->
                <div class="col-md-6 mt-3" *ngIf="versionFr">
                  <label for="inputColor" class="form-label">Code couleur</label>
                  <input type="color" class="form-control" id="inputColor" [(ngModel)]="medecin.colorTemplates" name="colorTemplates">
                </div>
                <!-- Logo -->
                <div class="col-md-6 mt-3" *ngIf="versionFr">
                  <label for="inputLogo" class="form-label">Logo</label>
                  <!-- <input type="file" class="form-control inputLogo" id="inputLogo" (change)="droppedFileLogo($event)" accept="image/*" name="logo"> -->
                  <div class="custom-file-upload">
                    <button type="button" class="form-control choose-file-button" id="chooseFileButton">Choisir un fichier</button>
                    <input type="file" class="form-control inputLogo" id="inputLogo" (change)="droppedFileLogo($event)" accept="image/*" name="logo">
                    <span class="form-control chosen-file-name" id="chosenFileName">Aucun fichier choisi</span>
                  </div>
                  <img src="{{ pathImageLogo }}" *ngIf="pathImageLogo" class="logo-cabinet">
                </div>
                <!-- First Name AR -->
                <div class="col-md-6" *ngIf="versionAr">
                  <label class="form-label">Nom </label>
                  <input type="text" class="form-control" placeholder="Nom" aria-label="First name" [(ngModel)]="medecin.nomAr" name="nomAr">
                </div>
                <!-- Last name AR -->
                <div class="col-md-6" *ngIf="versionAr">
                  <label class="form-label">Prénom </label>
                  <input type="text" class="form-control" placeholder="Prénom" aria-label="Last name" [(ngModel)]="medecin.prenomAr" name="prenomAr">
                </div>
                <!-- Spécialité AR -->
                <div class="col-md-6 mt-3" *ngIf="versionAr">
                  <label class="form-label">Spécialité </label>
                  <input type="text" class="form-control" placeholder="Spécialité" aria-label="Spécialité" [(ngModel)]="medecin.specialiteAr" name="specialiteAr">
                </div>
                <!-- Adresse AR -->
                <div class="col-md-6 mt-3" *ngIf="versionAr">
                  <label class="form-label">Adresse </label>
                  <input type="text" class="form-control" placeholder="Adresse" aria-label="Adresse" [(ngModel)]="medecin.adresseAr" name="adresseAr">
                </div>
              </div>
            </div>
          </div>
          <!-- Upload profile -->
          <div class="col-lg-4">
            <div class="bg-light rounded px-3 py-4">
              <h4 class="mb-5 mt-3 ml-2">Ajouter votre photo de profil</h4>
              <div class="text-center">
                <!-- Image upload -->
                <div class="square position-relative display-2 mb-3">
                  <img src="assets/images/person.png" class="img-profile" *ngIf="!pathImage" data-toggle="modal" data-target="#myModal7" style="cursor: pointer;" title="Ajouter votre photo de profil">
                  <img src="{{ pathImage }}" class="img-profile-new-added" *ngIf="pathImage" data-toggle="modal" data-target="#myModal7" title="Ajouter votre photo de profil">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Social address -->
        <div class="row mb-5">
          <!-- Section 1: Adresse de cabinet -->
          <div class="col-lg-6">
            <div class="bg-secondary-soft px-4 py-5 rounded">
              <h4 class="mb-5 mt-0">Adresse de cabinet</h4>
              <div class="row g-3">
                <!-- Ville -->
                <div class="col-md-6">
                  <label class="form-label">Ville*</label>
                  <select class="form-control" [(ngModel)]="medecin.adresseCabinet.ville" name="ville">
                    <option></option>
                    <option *ngFor="let ville of listefiltreville">{{ville}}</option>
                  </select>
                </div>
                <!-- Code postal -->
                <div class="col-md-6">
                  <label class="form-label">Code postal*</label>
                  <select class="form-control" [(ngModel)]="medecin.adresseCabinet.codePostal" name="codePostal">
                    <option></option>
                    <option *ngFor="let postal of listefiltrecode">{{postal}}</option>
                  </select>
                </div>
                <!-- Adresse -->
                <div class="col-md-12 mt-3">
                  <label class="form-label">Adresse*</label>
                  <input type="text" class="form-control" [(ngModel)]="medecin.adresseCabinet.adresse" name="adresse" aria-label="adresse" placeholder="Adresse">
                </div>
              </div>
            </div>
          </div>

          <!-- Section 2: Changer votre mot de passe -->
          <div class="col-lg-6">
            <div class="bg-secondary-soft px-4 py-5 rounded">
              <h4 class="mb-5 mt-0">Changer votre mot de passe</h4>
              <div class="row g-3">
                <!-- Old password -->
                <div class="col-md-6">
                  <label for="exampleInputPassword1" class="form-label">Ancien mot de passe *</label>
                  <input type="password" class="form-control" name="oldPassword" [(ngModel)]="oldPassword" id="exampleInputPassword1" placeholder="Ancien mot de passe">
                </div>
                <!-- New password -->
                <div class="col-md-6">
                  <label for="exampleInputPassword2" class="form-label">Nouveau mot de passe *</label>
                  <input type="password" class="form-control" name="newPassword" [(ngModel)]="newPassword" id="exampleInputPassword2" placeholder="Nouveau mot de passe">
                </div>
                <!-- Confirm password -->
                <div class="col-md-12 mt-3">
                  <label for="exampleInputPassword3" class="form-label">Confirmation de mot de passe *</label>
                  <input type="password" class="form-control" name="confirmationPassword" [(ngModel)]="confirmationPassword" id="exampleInputPassword3" placeholder="Confirmation de mot de passe">
                </div>
              </div>
              <div class="gap-3 d-md-flex justify-content-md-center text-center mt-3">
                <button type="button" class="btn btn-primary btn-lg btn-save-2" (click)="updatePassword()">Modifier mot de passe</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Social details -->
        <div class="row mb-5">
          <div class="col-lg-6">
            <div class="bg-secondary-soft px-4 py-5 rounded">
              <div class="row g-3">
                <h4 class="col-md-12 mb-5 mt-0">Détails des réseaux sociaux</h4>
                <!-- Facebook -->
                <div class="col-md-6">
                  <label class="form-label"><img src="assets/images/facebook-new.png" style="margin-top: -6px;"> Facebook</label>
                  <input type="text" class="form-control" [(ngModel)]="medecin.facebook" name="facebook" aria-label="Facebook" placeholder="https://www.facebook.com/profil">
                </div>
                <!-- Twitter -->
                <div class="col-md-6">
                  <label class="form-label"><img src="assets/images/twitter-new.png" style="margin-top: -6px;"> Twitter</label>
                  <input type="text" class="form-control" [(ngModel)]="medecin.twitter" name="twitter" aria-label="Twitter" placeholder="https://www.twitter.com/profil">
                </div>
                <!-- Linkedin -->
                <div class="col-md-6 mt-3">
                  <label class="form-label"><img src="assets/images/linkedin-new.png"> Linkedin</label>
                  <input type="text" class="form-control" [(ngModel)]="medecin.linkedin" name="linkedin" aria-label="Linkedin" placeholder="https://www.linkedin.com/in/profil">
                </div>
                <!-- Instagram -->
                <div class="col-md-6 mt-3">
                  <label class="form-label"><img src="assets/images/instagram-new.png" style="margin-top: -6px;"> Instagram</label>
                  <input type="text" class="form-control" [(ngModel)]="medecin.instagram" name="instagram" aria-label="Instagram" placeholder="https://www.instagram.com/profil">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Button -->
        <div class="gap-3 d-md-flex justify-content-md-center text-center mb-5">
          <button type="button" class="btn btn-primary btn-lg btn-save" (click)="updateInfoMedecin()">Enregistrer</button>
        </div>
      </form>
      <!-- Form END -->
    </div>
  </div>
</div>

<app-footer></app-footer>

<!-- modal modal ajout photo de profil -->
<div class="modal fade" id="myModal7" tabindex="-1" role="dialog" aria-labelledby="myModal7" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan" style="min-width: 650px !important;">
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut">
          Ajouter votre photo de profil
        </h3>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <h5 [ngClass]="{'active-type-photo-profile': isActiveDownloadPhoto}" (click)="isActiveDownloadPhotoFunction()">Télécharger une photo</h5>
          </div>
          <div class="col-md-6">
            <h5 [ngClass]="{'active-type-photo-profile': isActiveTakePhoto}" (click)="isActiveTakePhotoFunction()">Prendre une photo</h5>
          </div>
        </div>
        <!-- add photo -->
        <div class="row" *ngIf="isActiveDownloadPhoto">
          <div class="col-md-12">
            <div class="row mt-3">
              <div class="col-md-12">
                <div class="drop-container" id="dropcontainer">
                  <span class="drop-title">Déposer les fichiers ici</span>
                  ou
                  <input type="file" id="images" (change)="droppedFile($event)" accept="image/*" required style="display: none;">
                  <label for="images" class="custom-file-upload">
                    Choisir un fichier
                  </label>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <button class="btn button-save" style="background: #f16c28;" data-dismiss="modal" (click)="saveImages()">Sauvegarder</button>
              </div>
              <div class="col-md-6">
                <button class="btn button-save" data-dismiss="modal">Annuler</button>
              </div>
            </div>
          </div>
        </div>

        <!-- take photo -->
        <div class="row mt-3" *ngIf="isActiveTakePhoto">
          <div class="col-md-12">
            <div class="row">
              <p *ngIf="!authorized" id="alertActiverCamera">L'accès à la caméra/Micro est bloqué. <br> Veuillez l'activer dans les paramètres de votre navigateur.</p>
            </div>
            <div class="row">
              <div class="col-md-6">
                <video #video id="video" width="280" height="280" autoplay></video>
              </div>
              <div class="col-md-6">
                <canvas #canvas id="canvas" style="border-radius: 140px;" width="280" height="280"></canvas>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <br>
                <button class="btn button-save" style="background: #f16c28;" *ngIf="authorized" (click)="capture()">Prendre une photo</button>
                <br>
                <button class="btn button-save" style="width: 100%;" *ngIf="authorized && captures && captures.length > 0" data-dismiss="modal" (click)="saveImages()">Sauvegarder</button>
              </div>
              <div class="col-md-6">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- modal ajout photo de profil  -->