import { Component, OnInit } from '@angular/core';
import html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-certificat',
  templateUrl: './certificat.component.html',
  styleUrls: ['./certificat.component.css']
})
export class CertificatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const generateButton = document.querySelector('.btn-primary');
    const previewButton = document.querySelector('.btn-secondary');
    if (generateButton) {
      generateButton.addEventListener('click', this.generatePDF.bind(this));
    }
    if (previewButton) {
      previewButton.addEventListener('click', this.previewPDF.bind(this));
    }
  }

  hideButtons() {
    const buttons = document.querySelector('.no-print') as HTMLElement;
    if (buttons) {
      buttons.setAttribute('data-display', buttons.style.display);
      buttons.style.display = 'none';
    }
  }

  showButtons() {
    const buttons = document.querySelector('.no-print') as HTMLElement;
    if (buttons) {
      buttons.style.display = buttons.getAttribute('data-display') || '';
      buttons.removeAttribute('data-display');
    }
  }

  generatePDF() {
    this.hideButtons();
    this.addPDFStyles(); // Ajouter les styles PDF pour la génération

    const element = document.body;
    const opt = {
      margin: 0,
      filename: 'certification-medicale.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a5', orientation: 'portrait' }
    };

    html2pdf().from(element).set(opt).save().then(() => {
      this.showButtons();
      this.removePDFStyles(); // Retirer les styles PDF après la génération
    });
  }

  previewPDF() {
    this.hideButtons();
    this.addPDFStyles(); // Ajouter les styles PDF pour la prévisualisation

    const element = document.body;
    const opt = {
      margin: 0,
      filename: 'certification-medicale.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a5', orientation: 'portrait' }
    };

    html2pdf().from(element).set(opt).output('dataurlnewwindow').then(() => {
      this.showButtons();
      this.removePDFStyles(); // Retirer les styles PDF après la prévisualisation
    });
  }

  addPDFStyles() {
    document.body.classList.add('pdf-styles');

    // Réduire la taille du logo
    const logo = document.querySelector('.Logo') as HTMLElement;
    if (logo) {
      logo.style.display = 'block'; // Permettre la mise en forme des marges automatiques
      logo.style.marginLeft = 'auto'; // Centrer horizontalement
      logo.style.marginRight = 'auto'; // Centrer horizontalement
      logo.style.maxWidth = '90%'; // Changer la taille du logo selon vos besoins
    }

    //Réduire la taille de la police des éléments dans le header
    const headerItems = document.querySelectorAll('header *') as NodeListOf<HTMLElement>;
    if (headerItems) {
      headerItems.forEach(item => {
        item.style.fontSize = '12px'; // Changer la taille de police selon vos besoins
      });
    }
    // Diminuer la taille de la police du titre h1 à l'intérieur de .main-content
    const title = document.querySelector('.title h1') as HTMLElement;
    if (title) {
      title.style.fontSize = '20px'; // Modifier la taille de police pour l'élément h1
    }
        // Diminuer la taille de la police des éléments h5
        const h5Items = document.querySelectorAll('.main-content h5') as NodeListOf<HTMLElement>;
        h5Items.forEach(item => {
          item.style.fontSize = '16px';
        });
        const footerItems = document.querySelectorAll('footer *') as NodeListOf<HTMLElement>;
        if (footerItems) {
          footerItems.forEach(item => {
            item.style.fontSize = '12px'; // Changer la taille de police selon vos besoins
          });
        }
  }


  removePDFStyles() {
    document.body.classList.remove('pdf-styles');
  }

}
