<div class="bg-light">
    <div class="card" style="border: none;box-shadow: 0 8px 8px rgba(34, 34, 34, 0.03), 0 4px 16px rgba(34, 34, 34, 0.1);">
        <div class="card-body d-flex flex-wrap align-items-center justify-content-around"> <!-- Utilisation de flexbox pour aligner les éléments verticalement et s'enrouler sur plusieurs lignes -->
            <div class="col-md-4 d-flex align-items-center mb-3 mb-md-0"> <!-- Utilisation de flexbox pour aligner la colonne verticalement -->
                <div class="circle-photo mr-3"> <!-- Marge à droite pour séparer la photo des informations -->
                    <img src="../../../assets/user.png" class="img-fluid">
                </div>
                <div> <!-- Conteneur pour les informations du client -->
                    <h5 class="card-title">Foulen ben Foulen</h5>
                    <p class="card-text">Age: 26 ans</p>
                </div>
            </div>
            <div class="motif-title ml-md-3 mb-3 mb-md-0"> <!-- Conteneur pour le titre "Motif" -->
                <h5>Motif de consultation:</h5>
                <span class="badge badge-primary mr-1 p-2">BAV loin brutal œil gauche</span>
                <span class="badge badge-primary p-2">BAV loin brutal œil gauche</span>
            </div>
            <div class="motif-title ml-md-3 mb-3 mb-md-0"> <!-- Conteneur pour le titre "Motif" -->
                <h5>Historique: <a><i class="fa-solid fa-clock-rotate-left mr-1" title="Historique des consultations" style="font-size: 1rem;"></i></a> </h5>
                <span>Nombre des visites : 4</span>
            </div>
            <div class="motif-title ml-md-3"> <!-- Conteneur pour le titre "Motif" -->
                <h5>Durée de consultation:</h5>
                <div class="d-flex"> <!-- Utilisation de flexbox pour aligner les éléments horizontalement -->
                    <i class="fa-solid fa-stopwatch mr-2" style="color: #23B1A5;font-size: 1.2rem;"></i> <!-- Icone de chronomètre -->
                    <span>00:05:13</span> <!-- Durée de la consultation -->
                    <div class="ml-auto"> <!-- Utilisation de flexbox pour aligner les éléments sur la droite -->
                        <a title="Démarrer"><i class="fas fa-play ml-2 mr-3"></i></a> <!-- Bouton de démarrage avec icône -->
                        <a title="Pause"><i class="fas fa-pause mr-3"></i></a> <!-- Bouton de pause avec icône -->
                        <a title="Terminer la consultation"><i class="fa-solid fa-stop"></i></a> <!-- Bouton de fin avec icône -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
