<app-header></app-header>

<div class="container-fluid p-sm-5 bg-light">
    <div class="row">
        <div class="col-lg-12">
            <ul class="breadcrumb float-md-right">
                <li class="breadcrumb-item"><a class="color-blue" routerLink="/accueil"><i class="fa-solid fa-house"></i>
                        Accueil</a>
                </li>
                <li class="breadcrumb-item"><a class="color-blue" routerLink="/medecin/conclusion">
                        Conclusion + conduit à tenir</a>
                </li>
                <li class="breadcrumb-item active">Conclusion</li>
            </ul>
        </div>
    </div>
    <app-consultation-header></app-consultation-header>
    <div class="card mt-5 " style="border: none;box-shadow: 0 8px 8px rgba(34, 34, 34, 0.03), 0 4px 16px rgba(34, 34, 34, 0.1);">
        <div class="card-body">
            <div class="row justify-content-around">
                <div class="col-md-auto">
                    <a routerLink="" class="notSelected">Interrogatoire</a>
                </div>
                <div class="col-md-auto">
                    <a routerLink="" class="notSelected">Examen</a>
                </div>
                <div class="col-md-auto dropdown">
                    <a class="selected dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Conclusion + Conduit à tenir
                    </a>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" routerLink="/conclusion">Conclusion</a>
                        <a class="dropdown-item" routerLink="/Conduit-à-tenir">Conduit à tenir et prochaine consultation</a>
                    </div>
                </div>
                <div class="col-md-auto">
                    <a routerLink="" class="notSelected">Documents</a>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-lg-12">
            <div class="card p-4" style="border: none;box-shadow: 0 8px 8px rgba(34, 34, 34, 0.03), 0 4px 16px rgba(34, 34, 34, 0.1);">
                <div class="card-body">
                    <h5 class="mb-3">Anomalie à l'interrogatoire</h5>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="card border-custom">
                                <div class="card-body">
                                    <h6 class="text-center"><i class="fa-regular fa-eye"></i> œil gauche</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="card border-custom">
                                <div class="card-body">
                                    <h6 class="text-center"><i class="fa-regular fa-eye"></i> œil droit</h6>

                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 class="mt-3 mb-3">Anomalie à l'examen</h5>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="card border-custom">
                                <div class="card-body">
                                    <h6 class="text-center"><i class="fa-regular fa-eye"></i> œil gauche</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="card border-custom">
                                <div class="card-body">
                                    <h6 class="text-center"><i class="fa-regular fa-eye"></i> œil droit</h6>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col mt-3">
                            <label>Voulez-vous ajouter un diagnostic spécifique ?
                                <a class="addMotif"><i class="fa-solid fa-square-plus"></i></a>
                            </label>
                        </div>

                        <!-- <div  class="form-group col">
                            <label>Nouvelle motif</label>
                            <div class="form-group row">
                
                                <div class="col">
                                    <input class="form-control" type="text">
                                </div>
                                <div class="col">
                                    
                                    <button class="btn btn-secondary mr-2" (click)="cancelNotMotif()">Annuler</button>
                                    <button (click)="saveMotif(newMotif)" class="btn btn-primary">Enregister</button>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>
<app-footer></app-footer>