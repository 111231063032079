import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  medecin: any
  nom: any;
  prenom: any;
  email: any;
  tel: any;

  constructor(
    private authService: AuthService, 
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.authService.getProfileMedecin().subscribe((profile: any) => {
      this.medecin = profile.medecin;
      if (!this.medecin) {
        this.router.navigate(['/medecin/accueil']);
        return false;
      }
    },
      err => {
        this.router.navigate(['/medecin/accueil']);
        return false;
      });
  }

  logout(): void {
    this.authService.logout();
  }

}
