import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-conduit-atenir',
  templateUrl: './conduit-atenir.component.html',
  styleUrls: ['./conduit-atenir.component.css']
})
export class ConduitATenirComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  constructor(private formBuilder: FormBuilder) { }
  listCat = [
    "None",
    "Prescrire des lunettes",
    "Prescrire des lentilles",
    "Prescrire une ordonnance",
    "Prescrire une chirurgie",
    "Prescrire des examens complémentaires",
    "Prescrire un laser",
    "Prescrire un bilan orthoptique",
    "Prescrire un certificat médical",
    "Prescrire un certificat d'aptitude à la conduite",
    "Prescrire une lettre pour confrère"
]
prochaineConsultation = [
  "Après 15 jours","Après 1 mois","Après 6 mois","Après 1 an","Date libre"
]
listMotifs=["Amputation du champ visuel progressive",
    "Amputation du champvisuel brutal",
    "BAV loin brutal les deux yeux",
    "BAV loin brutal œil droit",
    "BAV loin brutal œil gauche",
    "BAV loin progressive des deux yeux",
    "BAV loin progressive œil droit",
    "BAV loin progressive œil gauche",
    "BAV près brutal les deux yeux",
    "BAV près brutal œil droit",
    "BAV près brutal œil gauche",
    "BAV près progressive des deux yeux",
    "BAV près progressive œil droit",
    "BAV près progressive œil gauche",
    "Blepharoptosis",
    "Blépharospasme",
    "Brouillard visuel",
    "Chute des cils",
    "Correction optique",
    "Diminution vision contraste",
    "Diplopie brutal",
    "Diplopie progressive",
    "Douleur Oculaire",
    "Douleur oculaire majorée à la mobilisation du globe",
    "Douleur palpébrale bilatérale",
    "Douleur palpébrale œil droit",
    "Douleur palpébrale œil gauche",
    "Flou visuel loin brutal les deux yeux",
    "Flou visuel loin brutal œil droit",
    "Flou visuel loin brutal œil gauche",
    "Flou visuel loin progressive des deux yeux",
    "Flou visuel loin progressive œil droit",
    "Flou visuel loin progressive œil gauche",
    "Flou visuel près brutal les deux yeux",
    "Flou visuel près brutal œil droit",
    "Flou visuel près brutal œil gauche",
    "Flou visuel près progressive des deux yeux",
    "Flou visuel près progressive œil droit",
    "Flou visuel près progressive œil gauche",
    "Halos colorés",
    "Héméralopie",
    "Lagophtalmie",
    "Larmoiement",
    "Myodésopsie",
    "Phosphene",
    "Photophobie",
    "Photopsie",
    "Prurit oculaire",
    "Rougeur oculaire",
    "Rougeur palpébrale",
    "Sécrétion claires",
    "Sécrétion purulentes",
    "Sensation de corp étranger",
    "Sensation de fatigue visuelle",
    "Sensation de sécheresse oculaire",
    "Voile noir devant les yeux",
    "Voile rouge devant les yeux"]
  ngOnInit(): void {
  //   this.registerForm = this.formBuilder.group({
  //     // conduitATenir: ['',],
  // },);
  }

}
