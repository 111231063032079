import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { _getURL, API_CONFIG } from 'config/API_CONFIG';
import { FileUploader } from 'ng2-file-upload';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AuthService } from 'src/app/services/auth/auth.service';
import { champsObligatoire } from 'src/app/services/constants/messages';
import { DocumentService } from 'src/app/services/document/document.service';
import { MedecinService } from 'src/app/services/medecin/medecin.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-ajout-document-patient',
  templateUrl: './ajout-document-patient.component.html',
  styleUrls: ['./ajout-document-patient.component.css']
})
export class AjoutDocumentPatientComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  champsObligatoire = champsObligatoire
  medecinId: any;
  medecin: any
  medecinUsedId: any
  patient: any
  selectedMedecin: any
  selectedDate: any
  today: Date = new Date()
  sizeLimitOne = 10000000; //10MB
  uploadedFiles: any;
  typeList = [
    "Analyses médicales",
    "Échographies",
    "Radiographies",
    "Scanners (tomodensitométrie)",
    "IRM (Imagerie par Résonance Magnétique)",
    "Rapports médicaux antérieurs",
    "Notes opératoires",
    "Résultats de tests spécialisés",
    "Ordonnance",
    "Certificat"
  ]
  satutsList = [
    "En cours", "Prêt"
  ]

  lengthMedecin: any
  pageNumber = 0
  staticMedecinscraped: any[] = [];
  medecins: any[] = []
  medecinsList: any[] = []
  resultss: any
  public uploader: FileUploader = new FileUploader({ url: _getURL(API_CONFIG.document) + '/medecin/patient' });
  constructor(private formBuilder: FormBuilder, public iziToast: Ng2IzitoastService, private router: Router, private authService: AuthService, private patientService: PatientService,
    private activatedRoute: ActivatedRoute, private medecinService: MedecinService, private documentService: DocumentService
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });

    this.registerForm = this.formBuilder.group({
      type: ['', [Validators.required]],
      date: ['', [Validators.required]],
      statut: ['', [Validators.required]],
      resultat: [''],
      demandePar: [''],
      autoriserAcces: [false],
    },);
    if (localStorage.getItem('medecin')) {
      this.medecinId = JSON.parse(localStorage.getItem('medecin')).id
    } else {
      return
    }
    this.authService.getProfileMedecin().subscribe((profile: any) => {
      this.medecin = profile.medecin;
      var medecinId = this.medecin._id;
      this.medecinUsedId = medecinId
      if (!this.medecin) {
        this.router.navigate(['/medecin/accueil']);
        return false;
      }
    },
      err => {
        this.router.navigate(['/medecin/accueil']);
        return false;
      });
    let params: any = this.activatedRoute.snapshot.params;
    this.patientService.getPatientByID(params.id).subscribe(patient => {
      this.patient = patient;
    },
      err => {
        this.router.navigate(['/medecin/patients-liste'])
        return false;
      });

    this.medecinService.getMedecinScapedcount().subscribe(length => {
      this.lengthMedecin = length;
    },
      err => {
        return false;
      });
    this.medecinService.getMedecinAccepted().subscribe((medecins: any[]) => {
      this.medecinService.getMedecinScapedBypage(this.pageNumber, 20).subscribe((medecinsss: any[]) => {
        this.medecins = medecins.concat(medecinsss);
        this.medecinsList = this.medecins.filter(el => el._id !== this.medecinId).map(el => ({
          fullName: el.nom + " " + el.prenom,
          _id: el._id
        }));
        this.staticMedecinscraped = medecinsss
        this.suivreMedecin(this.pageNumber + 1)
        //console.log(this.medecins.length)
      },
        err => {
          return false;
        });
    },
      err => {
        console.log(err);
        return false;
      });
  }
  get f() { return this.registerForm.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    // Prepare form data
    this.registerForm.value.patient = this.patient._id;
    this.registerForm.value.medecin = this.medecinId;
    this.registerForm.value.resultat = undefined;
    this.registerForm.value.titre = this.registerForm.value.type.substr(0, 3) + "_" +
      this.registerForm.value.date.substr(8, 2) +
      this.registerForm.value.date.substr(5, 2) +
      this.registerForm.value.date.substr(0, 4) + "_" +
      this.patient.nom + "_" + this.patient.prenom;

    if (this.registerForm.value.demandePar) {
      this.registerForm.value.demandePar = this.registerForm.value.demandePar._id;
    } else {
      this.registerForm.value.demandePar = undefined;
    }
    this.registerForm.value.key = uuidv4();
    // Prepare FormData
    if (this.uploadedFiles && this.uploadedFiles.length > 0) {
      this.registerForm.value.statut = "Prêt"
      this.registerForm.value.resulat = null
      let formData = new FormData();
      for (var i = 0; i < this.uploadedFiles.length; i++) {
        formData.append("uploads", this.uploadedFiles[i], this.uploadedFiles[i].name);
        if (i == 0) {
          formData.append("infoPatientMedecin", JSON.stringify(this.registerForm.value));
        }
      }
      this.documentService.saveDocumentByMedecin(formData, this.registerForm.value.patient, this.registerForm.value.key).subscribe((data) => {
        this.iziToast.show({
          message: 'Bien Ajouté',
          messageColor: '#386641',
          progressBarColor: '#6a994e',
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#dde5b6',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: false,
        });
        this.resetForm()
        this.router.navigate(['/medecin/consultation-liste', this.patient._id]);
      })
    }
    if (!this.uploadedFiles || (this.uploadedFiles && this.uploadedFiles.length < 1)) {
      this.registerForm.value.statut = "En cours"
      this.documentService.saveDocumentByPatientDocuement(this.registerForm.value)
        .subscribe(data => {
          this.iziToast.show({
            message: 'Bien Ajouté',
            messageColor: '#386641',
            progressBarColor: '#6a994e',
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#dde5b6',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay: false,
          });
          this.resetForm()
          this.router.navigate(['/medecin/consultation-liste', this.patient._id]);
        });
    }
  }
  suivreMedecin(counter) {
    if (this.lengthMedecin === this.staticMedecinscraped.length) {
      return true;
    }
    this.medecinService.getMedecinScapedBypage(counter, 20).subscribe((medecinsss: any[]) => {
      this.medecins.push(...medecinsss)
      this.staticMedecinscraped.push(...medecinsss)
      //this.mesMedecins.push(...medecinsss);
      counter++
      setTimeout(() => {    //<<<---    using ()=> syntax
        this.suivreMedecin(counter);
      }, 1000);
      return true;
    },
      err => {
        return false;
      });
  }
  fileChange(element: any) {
    const files = element.target.files;

    // Check if more than 5 files are selected
    if (files.length > 5) {
      this.uploadedFiles = undefined;
      this.resultss = null;
      this.iziToast.show({
        message: 'Sélectionnez seulement 5 fichiers !',
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return;
    }
    // Validate each file
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileType = this.registerForm.value.type;

      // Check specific conditions for "Ordonnance" or "Certificat"
      if ((fileType === "Ordonnance" || fileType === "Certificat") && files.length !== 1) {
        this.uploadedFiles = undefined;
        this.resultss = null;
        this.iziToast.show({
          message: 'Pour "Ordonnance" ou "Certificat", vous devez sélectionner 1 seul fichier !',
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
        });
        return;
      }
      // Check file type
      if (!file.type.match(/image.*|application\/pdf/)) {
        this.uploadedFiles = undefined;
        this.resultss = null;
        this.iziToast.show({
          message: 'Impossible de télécharger ce fichier !',
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
        });
        return;
      }

      // Check file size
      if (file.size > this.sizeLimitOne) {
        this.uploadedFiles = undefined;
        this.resultss = null;
        this.iziToast.show({
          message: 'Un fichier sélectionné est trop volumineux. La taille maximale est 10 Mo !',
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
        });
        return;
      }

    }

    // If all files are valid, set uploadedFiles
    this.uploadedFiles = files;
  }


  resetForm() {
    this.registerForm.reset();
    this.submitted = false;
  }
}
